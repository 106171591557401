<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="零漂、量漂校准记录表"
      fixed
      left-arrow
      @click-left="goBack"
      style="z-index: 99"
    >
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称"
          placeholder="请输入企业名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          v-model="manufacturer"
          label="气态污染物CEMS设备生产商"
          :colon="true"
          placeholder="请输入气态污染物CEMS设备生产商"
          :rules="[
            { required: true, message: '请输入气态污染物CEMS设备生产商' },
          ]"
        />
        <van-field
          v-model="specification"
          label="气态污染物CEMS设备规格型号"
          :colon="true"
          placeholder="请输入气态污染物CEMS设备规格型号"
          :rules="[
            { required: true, message: '请输入气态污染物CEMS设备规格型号' },
          ]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="time"
          label="校准日期"
          placeholder="点击选择日期"
          @click="datePickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择校准日期' }]"
        />
        <van-field
          v-model="particulate"
          label="颗粒物CEMS设备生产商"
          :colon="true"
          placeholder="请输入颗粒物CEMS设备生产商"
          type="textarea"
          :rules="[{ required: true, message: '请输入颗粒物CEMS设备生产商' }]"
        />
        <van-field
          v-model="specifications"
          label="气态污染物CEMS设备规格型号"
          :colon="true"
          placeholder="请输入气态污染物CEMS设备规格型号"
          :rules="[
            { required: true, message: '请输入气态污染物CEMS设备规格型号' },
          ]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="happenTime"
          label="校准开始时间"
          placeholder="点击选择时间"
          :colon="true"
          @click="dateTimePickerObj.pickerShow('happen')"
          :rules="[{ required: true, message: '请选择校准开始时间' }]"
        />

        <van-field
          v-model="site"
          label="安装地点"
          :colon="true"
          placeholder="请输入安装地点"
          :rules="[{ required: true, message: '请输入安装地点' }]"
        />
        <van-field
          v-model="management"
          :colon="true"
          label="维修管理单位"
          placeholder="请输入维修管理单位"
          :rules="[{ required: true, message: '请输入维修管理单位' }]"
        />
      </van-cell-group>
      <van-cell-group title=" ">
        <van-tabs
          v-model="active"
          :ellipsis="ellipsis"
          v-if="sheetList.length > 0"
        >
          <van-tab title="SO2分析仪校准" v-if="sheetList.includes('so')">
            <van-cell-group title="SO2分析仪校准">
              <van-field
                v-model="list.so.analyzerSo"
                :colon="true"
                label="分析仪原理"
                placeholder="请输入分析仪原理"
                :rules="[
                  {
                    required: true,
                    message: '请输入分析仪原理',
                  },
                ]"
              />
              <van-field
                v-model="list.so.rangeSo"
                :colon="true"
                label="分析仪量程"
                placeholder="请输入分析仪量程"
                :rules="[{ required: true, message: '请输入分析仪量程' }]"
              />
              <van-field
                v-model="list.so.calculationSo"
                :colon="true"
                label="计算单位"
                placeholder="请输入计算单位"
                :rules="[{ required: true, message: '请输入计算单位' }]"
              />
            </van-cell-group>
            <van-cell-group title="零点漂移校准">
              <van-field
                v-model="list.so.zerogasSo"
                :colon="true"
                label="零气浓度值"
                placeholder="请输入零气浓度值"
                :rules="[{ required: true, message: '请输入零气浓度值' }]"
              />
              <van-field
                v-model="list.so.frontSo"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.so.driftSo"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.so.normalSo"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.so.afterSo"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.so.beforeSo"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>

            <van-cell-group title="量程漂移校准">
              <van-field
                v-model="list.so.zerogasSoLiang"
                :colon="true"
                label="标气浓度值"
                placeholder="请输入标气浓度值"
                :rules="[{ required: true, message: '请输入标气浓度值' }]"
              />
              <van-field
                v-model="list.so.frontSoLiang"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.so.driftSoLiang"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.so.normalSoLiang"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.so.afterSoLiang"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.so.beforeSoLiang"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="NOx分析仪校准" v-if="sheetList.includes('no')">
            <van-cell-group title="NOx分析仪校准">
              <van-field
                v-model="list.no.analyzerNo"
                :colon="true"
                label="分析仪原理"
                placeholder="请输入分析仪原理"
                :rules="[{ required: true, message: '请输入分析仪原理' }]"
              />
              <van-field
                v-model="list.no.rangeNo"
                :colon="true"
                label="分析仪量程"
                placeholder="请输入分析仪量程"
                :rules="[{ required: true, message: '请输入分析仪量程' }]"
              />
              <van-field
                v-model="list.no.calculationNo"
                :colon="true"
                label="计算单位"
                placeholder="请输入计算单位"
                :rules="[{ required: true, message: '请输入计算单位' }]"
              />
            </van-cell-group>

            <van-cell-group title="零点漂移校准">
              <van-field
                v-model="list.no.zerogasNo"
                :colon="true"
                label="零气浓度值"
                placeholder="请输入零气浓度值"
                :rules="[{ required: true, message: '请输入零气浓度值' }]"
              />
              <van-field
                v-model="list.no.frontNo"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.no.driftNo"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.no.normalNo"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.no.afterNo"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.no.beforeNo"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
            <van-cell-group title="量程漂移校准">
              <van-field
                v-model="list.no.zerogasNoLiang"
                :colon="true"
                label="标气浓度值"
                placeholder="请输入标气浓度值"
                :rules="[{ required: true, message: '请输入标气浓度值' }]"
              />
              <van-field
                v-model="list.no.frontNoLiang"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.no.driftNoLiang"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.no.normalNoLiang"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.no.afterNoLiang"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.no.beforeNoLiang"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="O2分析仪校准" v-if="sheetList.includes('o')">
            <van-cell-group title="O2分析仪校准">
              <van-field
                v-model="list.o.analyzerO"
                :colon="true"
                label="分析仪原理"
                placeholder="请输入分析仪原理"
                :rules="[{ required: true, message: '请输入分析仪原理' }]"
              />
              <van-field
                v-model="list.o.rangeO"
                :colon="true"
                label="分析仪量程"
                placeholder="请输入分析仪量程"
                :rules="[{ required: true, message: '请输入分析仪量程' }]"
              />
              <van-field
                v-model="list.o.calculationO"
                :colon="true"
                label="计算单位"
                placeholder="请输入计算单位"
                :rules="[{ required: true, message: '请输入计算单位' }]"
              />
            </van-cell-group>
            <van-cell-group title="零点漂移校准">
              <van-field
                v-model="list.o.zerogasO"
                :colon="true"
                label="零气浓度值"
                placeholder="请输入零气浓度值"
                :rules="[{ required: true, message: '请输入零气浓度值' }]"
              />
              <van-field
                v-model="list.o.frontO"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.o.driftO"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.o.normalO"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.o.afterO"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.o.beforeO"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
            <van-cell-group title="量程漂移校准">
              <van-field
                v-model="list.o.zerogasOLiang"
                :colon="true"
                label="标气浓度值"
                placeholder="请输入标气浓度值"
                :rules="[{ required: true, message: '请输入标气浓度值' }]"
              />
              <van-field
                v-model="list.o.frontOLiang"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.o.driftOLiang"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.o.normalOLiang"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.o.afterOLiang"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.o.beforeOLiang"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="流速仪校准" v-if="sheetList.includes('liu')">
            <van-cell-group title="流速仪校准">
              <van-field
                v-model="list.liu.analyzerLiu"
                :colon="true"
                label="分析仪原理"
                placeholder="请输入分析仪原理"
                :rules="[{ required: true, message: '请输入分析仪原理' }]"
              />
              <van-field
                v-model="list.liu.rangeLiu"
                :colon="true"
                label="分析仪量程"
                placeholder="请输入分析仪量程"
                :rules="[{ required: true, message: '请输入分析仪量程' }]"
              />
              <van-field
                v-model="list.liu.calculationLiu"
                :colon="true"
                label="计算单位"
                placeholder="请输入计算单位"
                :rules="[{ required: true, message: '请输入计算单位' }]"
              />
            </van-cell-group>

            <van-cell-group title="零点漂移校准">
              <van-field
                v-model="list.liu.zerogasLiu"
                :colon="true"
                label="零气浓度值"
                placeholder="请输入零气浓度值"
                :rules="[{ required: true, message: '请输入零气浓度值' }]"
              />
              <van-field
                v-model="list.liu.frontLiu"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.liu.driftLiu"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.liu.normalLiu"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.liu.afterLiu"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.liu.beforeLiu"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="颗粒物校准" v-if="sheetList.includes('ke')">
            <van-cell-group title="颗粒物校准">
              <van-field
                v-model="list.ke.analyzerKe"
                :colon="true"
                label="分析仪原理"
                placeholder="请输入分析仪原理"
                :rules="[{ required: true, message: '请输入分析仪原理' }]"
              />
              <van-field
                v-model="list.ke.rangeKe"
                :colon="true"
                label="分析仪量程"
                placeholder="请输入分析仪量程"
                :rules="[{ required: true, message: '请输入分析仪量程' }]"
              />
              <van-field
                v-model="list.ke.calculationKe"
                :colon="true"
                label="计算单位"
                placeholder="请输入计算单位"
                :rules="[{ required: true, message: '请输入计算单位' }]"
              />
            </van-cell-group>

            <van-cell-group title="零点漂移校准">
              <van-field
                v-model="list.ke.zerogasKe"
                :colon="true"
                label="零气浓度值"
                placeholder="请输入零气浓度值"
                :rules="[{ required: true, message: '请输入零气浓度值' }]"
              />
              <van-field
                v-model="list.ke.frontKe"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.ke.driftKe"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.ke.normalKe"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.ke.afterKe"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.ke.beforeKe"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
            <van-cell-group title="量程漂移校准">
              <van-field
                v-model="list.ke.zerogasKeLiang"
                :colon="true"
                label="标气浓度值"
                placeholder="请输入标气浓度值"
                :rules="[{ required: true, message: '请输入标气浓度值' }]"
              />
              <van-field
                v-model="list.ke.frontKeLiang"
                :colon="true"
                label="校前测试值"
                placeholder="请输入校前测试值"
                :rules="[{ required: true, message: '请输入校前测试值' }]"
              />
              <van-field
                v-model="list.ke.driftKeLiang"
                :colon="true"
                label="零点漂移%"
                placeholder="请输入零点漂移%"
                :rules="[{ required: true, message: '请输入零点漂移%' }]"
              />
              <van-cell title="仪器校准是否正常：">
                <van-radio-group
                  v-model="list.ke.normalKeLiang"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell>
              <van-field
                v-model="list.ke.afterKeLiang"
                :colon="true"
                label="校准后测试值"
                placeholder="请输入校准后测试值"
                :rules="[{ required: true, message: '请输入校准后测试值' }]"
              />
              <van-field
                v-model="list.ke.beforeKeLiang"
                :colon="true"
                label="上次校准后测试值"
                placeholder="请输入上次校准后测试值"
                :rules="[{ required: true, message: '请输入上次校准后测试值' }]"
              />
            </van-cell-group>
          </van-tab>
        </van-tabs>
        <van-cell-group title=" ">
          <van-field
            v-model="calibrator"
            :colon="true"
            label="校准人"
            placeholder="请输入校准人"
            :rules="[{ required: true, message: '请输入校准人' }]"
          />
          <van-field
            readonly
            clickable
            :colon="true"
            name="datetimePicker"
            :value="checkTime"
            label="校准结束时间"
            placeholder="点击选择时间"
            @click="dateTimePickerObj.pickerShow('check')"
            :rules="[{ required: true, message: '请选择校准结束时间' }]"
          />

          <van-field
            v-model="signature"
            :colon="true"
            label="企业负责人（签字）"
            placeholder="请输入企业负责人（签字）"
            :rules="[{ required: true, message: '请输入企业负责人（签字）' }]"
          />
        </van-cell-group>
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="datePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickerObj.value"
        @confirm="datePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 动作面板 -->
    <van-action-sheet
      v-model="sheetShow"
      :actions="sheetActions"
      @select="onSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      soObj: {
        analyzerSo: "",
        rangeSo: "",
        calculationSo: "",
        zerogasSo: "",
        frontSo: "",
        driftSo: "",
        normalSo: "1",
        afterSo: "",
        beforeSo: "",
        zerogasSoLiang: "",
        frontSoLiang: "",
        driftSoLiang: "",
        normalSoLiang: "1",
        afterSoLiang: "",
        beforeSoLiang: "",
      },
      noObj: {
        analyzerNo: "",
        rangeNo: "",
        calculationNo: "",
        zerogasNo: "",
        frontNo: "",
        driftNo: "",
        normalNo: "1",
        afterNo: "",
        beforeNo: "",
        zerogasNoLiang: "",
        frontNoLiang: "",
        driftNoLiang: "",
        normalNoLiang: "1",
        afterNoLiang: "",
        beforeNoLiang: "",
      },
      oObj: {
        analyzerO: "",
        rangeO: "",
        calculationO: "",
        zerogasO: "",
        frontO: "",
        driftO: "",
        normalO: "1",
        afterO: "",
        beforeO: "",
        zerogasOLiang: "",
        frontOLiang: "",
        driftOLiang: "",
        normalOLiang: "1",
        afterOLiang: "",
        beforeOLiang: "",
      },
      liuObj: {
        analyzerLiu: "",
        rangeLiu: "",
        calculationLiu: "",
        zerogasLiu: "",
        frontLiu: "",
        driftLiu: "",
        normalLiu: "1",
        afterLiu: "",
        beforeLiu: "",
      },
      keObj: {
        analyzerKe: "",
        rangeKe: "",
        calculationKe: "",
        zerogasKe: "",
        frontKe: "",
        driftKe: "",
        normalKe: "1",
        afterKe: "",
        beforeKe: "",
        zerogasKeLiang: "",
        frontKeLiang: "",
        driftKeLiang: "",
        normalKeLiang: "1",
        afterKeLiang: "",
        beforeKeLiang: "",
      },
      sheetShow: false,
      sheetActions: [
        { name: "SO2分析仪校准", type: "so" },
        { name: "NOx分析仪校准", type: "no" },
        { name: "O2分析仪校准", type: "o" },
        { name: "流速仪校准", type: "liu" },
        { name: "颗粒物校准", type: "ke" },
      ],
      sheetList: [],
      ellipsis: false,
      active: "",
      dateTimePickerObj: {
        value: new Date(),
        type: "",
        isShow: false,
        pickerShow: (type) => {
          this.dateTimePickerObj.isShow = true;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "happen":
              if (!this.happenTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.happenTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "check":
              if (!this.checkTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.checkTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "happen":
              this.happenTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "check":
              this.checkTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
          }
        },
      },
      datePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.datePickerObj.isShow = true;
          if (!this.time) {
            this.datePickerObj.value = new Date();
          } else {
            this.datePickerObj.value = new Date(
              this.$moment(this.time).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.datePickerObj.isShow = false;
          this.time = this.$moment(this.datePickerObj.value).format(
            "YYYY-MM-DD"
          );
        },
      },
      enterpriseName: "",
      manufacturer: "",
      specification: "",
      time: "",
      particulate: "",
      specifications: "",
      happenTime: "",
      site: "",
      management: "",

      list: {
        // analyzerSo: "",
        // rangeSo: "",
        // calculationSo: "",
        // zerogasSo: "",
        // frontSo: "",
        // driftSo: "",
        // normalSo: "1",
        // afterSo: "",
        // beforeSo: "",
        // zerogasSoLiang: "",
        // frontSoLiang: "",
        // driftSoLiang: "",
        // normalSoLiang: "1",
        // afterSoLiang: "",
        // beforeSoLiang: "",
        // analyzerNo: "",
        // rangeNo: "",
        // calculationNo: "",
        // zerogasNo: "",
        // frontNo: "",
        // driftNo: "",
        // normalNo: "1",
        // afterNo: "",
        // beforeNo: "",
        // zerogasNoLiang: "",
        // frontNoLiang: "",
        // driftNoLiang: "",
        // normalNoLiang: "1",
        // afterNoLiang: "",
        // beforeNoLiang: "",
        // analyzerO: "",
        // rangeO: "",
        // calculationO: "",
        // zerogasO: "",
        // frontO: "",
        // driftO: "",
        // normalO: "1",
        // afterO: "",
        // beforeO: "",
        // zerogasOLiang: "",
        // frontOLiang: "",
        // driftOLiang: "",
        // normalOLiang: "1",
        // afterOLiang: "",
        // beforeOLiang: "",
        // analyzerLiu: "",
        // rangeLiu: "",
        // calculationLiu: "",
        // zerogasLiu: "",
        // frontLiu: "",
        // driftLiu: "",
        // normalLiu: "1",
        // afterLiu: "",
        // beforeLiu: "",
        // analyzerKe: "",
        // rangeKe: "",
        // calculationKe: "",
        // zerogasKe: "",
        // frontKe: "",
        // driftKe: "",
        // normalKe: "1",
        // afterKe: "",
        // beforeKe: "",
        // zerogasKeLiang: "",
        // frontKeLiang: "",
        // driftKeLiang: "",
        // normalKeLiang: "1",
        // afterKeLiang: "",
        // beforeKeLiang: ""
      },
      calibrator: "",
      checkTime: "",
      signature: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 动作面板选中
    onSheetSelect(item) {
      if (this.sheetList.includes(item.type)) {
        Toast.fail(`${item.name}已存在，请勿重复添加`);
      } else {
        this.sheetList.push(item.type);
        switch (item.type) {
          case "so":
            this.initSo();
            break;
          case "no":
            this.initNo();
            break;
          case "o":
            this.initO();
            break;
          case "liu":
            this.initLiu();
            break;
          case "ke":
            this.initKe();
            break;
        }
      }
    },
    // 初始化SO2分析仪校准
    initSo() {
      this.list.so = this.soObj;
    },
    // NOx分析仪校准
    initNo() {
      this.list.no = this.noObj;
    },
    // O2分析仪校准
    initO() {
      this.list.o = this.oObj;
    },
    // 流速仪校准
    initLiu() {
      this.list.liu = this.liuObj;
    },
    // 颗粒物校准
    initKe() {
      this.list.ke = this.keObj;
    },
    // 添加记录
    addList() {
      this.sheetShow = true;
    },
    // 提交
    onSubmit() {
      const obj = {
        enterpriseName: this.$route.query.enterpriseName,
        manufacturer: this.manufacturer,
        specification: this.specification,
        time: this.time,
        particulate: this.particulate,
        specifications: this.specifications,
        happenTime: this.happenTime,
        site: this.site,
        management: this.management,
        list: this.list,

        // analyzerSo: this.analyzerSo,
        // rangeSo: this.rangeSo,
        // calculationSo: this.calculationSo,
        // zerogasSo: this.zerogasSo,
        // frontSo: this.frontSo,
        // driftSo: this.driftSo,
        // normalSo: this.normalSo,
        // afterSo: this.afterSo,
        // zerogasSoLiang: this.zerogasSoLiang,
        // frontSoLiang: this.frontSoLiang,
        // driftSoLiang: this.driftSoLiang,
        // normalSoLiang: this.normalSoLiang,
        // afterSoLiang: this.afterSoLiang,

        // analyzerNo: this.analyzerNo,
        // rangeNo: this.rangeNo,
        // calculationNo: this.calculationNo,
        // zerogasNo: this.zerogasNo,
        // frontNo: this.frontNo,
        // driftNo: this.driftNo,
        // normalNo: this.normalNo,
        // afterNo: this.afterNo,
        // zerogasNoLiang: this.zerogasNoLiang,
        // frontNoLiang: this.frontNoLiang,
        // driftNoLiang: this.driftNoLiang,
        // normalNoLiang: this.normalNoLiang,
        // afterNoLiang: this.afterNoLiang,

        // analyzerO: this.analyzerO,
        // rangeO: this.rangeO,
        // calculationO: this.calculationO,
        // zerogasO: this.zerogasSo,
        // frontO: this.frontO,
        // driftO: this.driftO,
        // normalO: this.normalO,
        // afterO: this.afterO,
        // zerogasOLiang: this.zerogasOLiang,
        // frontOLiang: this.frontOLiang,
        // driftOLiang: this.driftOLiang,
        // normalOLiang: this.normalOLiang,
        // afterOLiang: this.afterOLiang,

        // analyzerLiu: this.analyzerLiu,
        // rangeLiu: this.rangeLiu,
        // calculationLiu: this.calculationLiu,
        // zerogasLiu: this.zerogasLiu,
        // frontLiu: this.frontLiu,
        // driftLiu: this.driftLiu,
        // normalLiu: this.normalLiu,
        // afterLiu: this.afterLiu,
        // zerogasLiuLiang: this.zerogasLiuLiang,
        // frontLiuLiang: this.frontLiuLiang,
        // driftLiuLiang: this.driftLiuLiang,
        // normalLiuLiang: this.normalLiuLiang,
        // afterLiuLiang: this.afterLiuLiang,

        // analyzerKe: this.analyzerKe,
        // rangeKe: this.rangeKe,
        // calculationKe: this.calculationKe,
        // zerogasKe: this.zerogasKe,
        // frontKe: this.frontKe,
        // driftKe: this.driftKe,
        // normalKe: this.normalKe,
        // afterKe: this.afterKe,
        // zerogasKeuLiang: this.zerogasKeuLiang,
        // frontKeLiang: this.frontKeLiang,
        // driftKeLiang: this.driftKeLiang,
        // normalKeLiang: this.normalKeLiang,
        // afterKeLiang: this.afterKeLiang,

        calibrator: this.calibrator,
        checkTime: this.checkTime,
        signature: this.signature,
      };
      if (this.sheetList.length == 0) {
        Toast.fail("请至少添加一项校准记录");
        return;
      }
      for (const key in this.list) {
        if (this.list[key] === "") {
          Toast.fail("请填写完整的信息");
          return;
        }
      }
      if (this.sheetList.includes("so")) {
        for (const k in this.list.so) {
          if (this.list.so[k] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      if (this.sheetList.includes("no")) {
        for (const k in this.list.no) {
          if (this.list.no[k] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      if (this.sheetList.includes("o")) {
        for (const k in this.list.o) {
          if (this.list.o[k] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      if (this.sheetList.includes("liu")) {
        for (const k in this.list.liu) {
          if (this.list.liu[k] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      if (this.sheetList.includes("ke")) {
        for (const k in this.list.ke) {
          if (this.list.ke[k] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 7,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.van-tabs__content {
  .van-cell-group__title {
    background-color: #e9eef2;
  }
}
</style>
