<template>
  <div class="operation" v-padding:top="46">
    <van-nav-bar title="运维打卡" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-cell-group title="运维信息">
      <van-cell title="企业名称" :value="$route.query.enterpriseName" />
      <van-cell title="站点名称" :value="$route.query.pointName" />
      <van-cell title="SN号" :value="$route.query.sn" />
    </van-cell-group>
    <div style="margin: 16px; margin-top: 80px">
      <van-button
        round
        block
        size="large"
        type="info"
        v-if="maintenanceStatus == 0"
        @click="startOperation"
      >
        开始运维
      </van-button>
    </div>
    <div style="margin: 16px; margin-top: 80px">
      <van-button
        round
        block
        size="large"
        type="info"
        v-if="maintenanceStatus == 1"
        @click="toFillTable"
      >
        填写表格
      </van-button>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        size="large"
        type="info"
        v-if="maintenanceStatus == 1"
        @click="endOperation"
      >
        结束运维
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      maintenanceStatus: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getPonitMaintenanceStatus();
  },
  methods: {
    // 结束运维
    endOperation() {
      const data = {
        userId: localStorage.getItem("userId"),
        sn: this.$route.query.sn,
        endTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      this.$api.operation.getEndOperation(data).then((res) => {
        if (res.data.state == 0) {
          Toast.success("打卡成功");
          this.goBack();
        }
      });
    },
    // 开始运维
    startOperation() {
      const data = {
        userId: localStorage.getItem("userId"),
        sn: this.$route.query.sn,
        startTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      this.$api.operation.getStartOperation(data).then((res) => {
        if (res.data.state == 0) {
          this.$router.push({
            name: "Table",
            query: {
              sn: data.sn,
              enterpriseName: this.$route.query.enterpriseName,
              pointName: this.$route.query.pointName,
            },
          });
        }
      });
    },
    // 开始运维
    toFillTable() {
      this.$router.push({
        name: "Table",
        query: {
          sn: this.$route.query.sn,
          enterpriseName: this.$route.query.enterpriseName,
          pointName: this.$route.query.pointName,
        },
      });
    },
    // 查询站点运维状态
    getPonitMaintenanceStatus() {
      this.maintenanceStatus = 0;
      const data = {
        userId: localStorage.getItem("userId"),
        sn: this.$route.query.sn,
      };
      this.$api.operation.getPointMtn(data).then((res) => {
        if (res.data.state == 0) {
          this.maintenanceStatus = res.data.data.maintenanceStatus;
          console.log(this.maintenanceStatus);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
