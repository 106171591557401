<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="运营维护日常巡检表"
      fixed
      left-arrow
      @click-left="goBack"
    >
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          :colon="true"
          readonly
          clickable
          name="datetimePicker"
          :value="dateTime"
          label="巡检日期"
          placeholder="点击选择巡检日期"
          @click="dataPickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择巡检日期' }]"
        />
        <van-field
          :colon="true"
          v-model="personnel"
          label="巡检人员"
          placeholder="请输入巡检人员"
          :rules="[{ required: true, message: '请输入巡检人员' }]"
        />
      </van-cell-group>
      <van-cell-group title="维护预备">
        <van-field
          :colon="true"
          v-model="log"
          label="查询日志、报警信息"
          placeholder="请输入查询日志、报警信息"
          :rules="[{ required: true, message: '请输入查询日志、报警信息' }]"
        />
        <van-field
          :colon="true"
          v-model="parameter"
          label="检查仪器参数、耗材、试剂、标液"
          type="textarea"
          placeholder="请输入检查仪器参数、耗材、试剂、标液"
          :rules="[
            { required: true, message: '请输入检查仪器参数、耗材、试剂、标液' },
          ]"
        />
        <van-field
          :colon="true"
          v-model="situation"
          label="采样泵采排水及管路情况"
          type="textarea"
          placeholder="请输入采样泵采排水及管路情况"
          :rules="[{ required: true, message: '请输入采样泵采排水及管路情况' }]"
        />
      </van-cell-group>
      <van-cell-group title="辅助设备检查">
        <van-field
          :colon="true"
          v-model="hygiene"
          label="站房卫生"
          placeholder="请输入站房卫生"
          :rules="[{ required: true, message: '请输入站房卫生' }]"
        />
        <van-field
          :colon="true"
          v-model="sealingPerformance"
          label="站房门窗的密封性检查"
          type="textarea"
          placeholder="请输入站房门窗的密封性检查"
          :rules="[{ required: true, message: '请输入站房门窗的密封性检查' }]"
        />
        <van-field
          :colon="true"
          v-model="powerSupply"
          label="供电系统（稳压电源、UPS等）"
          type="textarea"
          placeholder="请输入供电系统（稳压电源、UPS等）"
          :rules="[
            { required: true, message: '请输入供电系统（稳压电源、UPS等）' },
          ]"
        />
        <van-field
          :colon="true"
          v-model="network"
          label="通讯系统（网络）"
          type="textarea"
          placeholder="请输入通讯系统（网络）"
          :rules="[{ required: true, message: '请输入通讯系统（网络）' }]"
        />
        <van-field
          :colon="true"
          v-model="identical"
          label="分析仪与数采数据是否一致"
          type="textarea"
          placeholder="请输入分析仪与数采数据是否一致"
          :rules="[
            { required: true, message: '请输入分析仪与数采数据是否一致' },
          ]"
        />
        <van-field
          :colon="true"
          v-model="temperatureAndHumidity"
          label="室内温湿度及空调"
          type="textarea"
          placeholder="请输入室内温湿度及空调"
          :rules="[{ required: true, message: '请输入室内温湿度及空调' }]"
        />
      </van-cell-group>
      <van-cell-group title="水污染源在线监测仪器">
        <van-field
          :colon="true"
          v-model="w01018"
          label="CODcr"
          type="textarea"
          placeholder="请输入CODcr"
          :rules="[{ required: true, message: '请输入CODcr' }]"
        />
        <van-field
          :colon="true"
          v-model="w21003"
          label="氨氮"
          type="textarea"
          placeholder="请输入氨氮"
          :rules="[{ required: true, message: '请输入氨氮' }]"
        />
        <van-field
          :colon="true"
          v-model="w21011"
          label="总磷"
          type="textarea"
          placeholder="请输入总磷"
          :rules="[{ required: true, message: '请输入总磷' }]"
        />
        <van-field
          :colon="true"
          v-model="w21001"
          label="总氮"
          type="textarea"
          placeholder="请输入总氮"
          :rules="[{ required: true, message: '请输入总氮' }]"
        />
        <van-field
          :colon="true"
          v-model="waterSampler"
          label="水质采样器"
          type="textarea"
          placeholder="请输入水质采样器"
          :rules="[{ required: true, message: '请输入水质采样器' }]"
        />
        <van-field
          :colon="true"
          v-model="w01001"
          label="pH计"
          type="textarea"
          placeholder="请输入pH计"
          :rules="[{ required: true, message: '请输入pH计' }]"
        />
        <van-field
          :colon="true"
          v-model="w00000"
          label="流量计"
          type="textarea"
          placeholder="请输入流量计"
          :rules="[{ required: true, message: '请输入流量计' }]"
        />
        <van-field
          :colon="true"
          v-model="dataInstrument"
          label="数采仪"
          type="textarea"
          placeholder="请输入数采仪"
          :rules="[{ required: true, message: '请输入数采仪' }]"
        />
      </van-cell-group>
      <van-cell-group title="周期维护">
        <van-field
          :colon="true"
          v-model="instrumentClear"
          label="仪器清洗"
          type="textarea"
          placeholder="请输入仪器清洗"
          :rules="[{ required: true, message: '请输入仪器清洗' }]"
        />
        <van-field
          :colon="true"
          v-model="pipingClear"
          label="管路清洗"
          type="textarea"
          placeholder="请输入管路清洗"
          :rules="[{ required: true, message: '请输入管路清洗' }]"
        />
        <van-field
          :colon="true"
          v-model="collect"
          label="废液收集"
          type="textarea"
          placeholder="请输入废液收集"
          :rules="[{ required: true, message: '请输入废液收集' }]"
        />
        <van-field
          :colon="true"
          v-model="change"
          label="试剂、耗材更换"
          type="textarea"
          placeholder="请输入试剂、耗材更换"
          :rules="[{ required: true, message: '请输入试剂、耗材更换' }]"
        />
        <van-field
          :colon="true"
          v-model="hygieneClear"
          label="卫生打扫"
          type="textarea"
          placeholder="请输入卫生打扫"
          :rules="[{ required: true, message: '请输入卫生打扫' }]"
        />
        <van-field
          :colon="true"
          v-model="pointLog"
          label="站房记录"
          type="textarea"
          placeholder="请输入站房记录"
          :rules="[{ required: true, message: '请输入站房记录' }]"
        />
      </van-cell-group>
      <van-cell-group title="其他情况">
        <van-field
          :colon="true"
          v-model="other"
          label="其他情况"
          type="textarea"
          placeholder="请输入其他情况"
          :rules="[{ required: true, message: '请输入其他情况' }]"
        />
      </van-cell-group>
      <van-cell-group title="此次维护纪要">
        <van-field
          :colon="true"
          v-model="summary"
          label="此次维护纪要"
          type="textarea"
          placeholder="请输入此次维护纪要"
          :rules="[{ required: true, message: '请输入此次维护纪要' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="dataPickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="dataPickerObj.value"
        @confirm="dataPickerObj.pickerConfirm"
        @cancel="dataPickerObj.isShow"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dataPickerObj: {
        isShow: false,
        value: new Date(),
        pickerShow: () => {
          this.dataPickerObj.isShow = false;
          if (!this.dateTime) {
            this.dataPickerObj.value = new Date();
          } else {
            this.dataPickerObj.value = new Date(
              this.$moment(this.dateTime).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTime = this.$moment(this.dataPickerObj.value).format(
            "YYYY-MM-DD"
          );
          this.dataPickerObj.isShow = false;
        },
      },
      dateTime: this.$moment().format("YYYY-MM-DD"),
      personnel: "",
      log: "",
      parameter: "",
      situation: "",
      hygiene: "",
      sealingPerformance: "",
      powerSupply: "",
      network: "",
      identical: "",
      temperatureAndHumidity: "",
      w01018: "",
      w21003: "",
      w21011: "",
      w21001: "",
      waterSampler: "",
      w01001: "",
      w00000: "",
      dataInstrument: "",
      instrumentClear: "",
      pipingClear: "",
      collect: "",
      change: "",
      hygieneClear: "",
      pointLog: "",
      other: "",
      summary: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      const obj = {
        dateTime: this.dateTime,
        personnel: this.personnel,
        log: this.log,
        parameter: this.parameter,
        situation: this.situation,
        hygiene: this.hygiene,
        sealingPerformance: this.sealingPerformance,
        powerSupply: this.powerSupply,
        network: this.network,
        identical: this.identical,
        temperatureAndHumidity: this.temperatureAndHumidity,
        w01018: this.w01018,
        w21003: this.w21003,
        w21011: this.w21011,
        w21001: this.w21001,
        waterSampler: this.waterSampler,
        w01001: this.w01001,
        w00000: this.w00000,
        dataInstrument: this.dataInstrument,
        instrumentClear: this.instrumentClear,
        pipingClear: this.pipingClear,
        collect: this.collect,
        change: this.change,
        hygieneClear: this.hygieneClear,
        pointLog: this.pointLog,
        other: this.other,
        summary: this.summary,
      };
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 6,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
