<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="CEMS维修记录表" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称"
          placeholder="请输入企业名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="time"
          label="日期"
          placeholder="点击选择日期"
          @click="datePickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择日期' }]"
        />
        <van-field
          readonly
          v-model="this.$route.query.pointName"
          label="站点名称"
          :colon="true"
          placeholder="请输入站点名称"
          :rules="[{ required: true, message: '请输入站点名称' }]"
        />
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="checkTime"
          label="停机时间"
          placeholder="点击选择停机时间"
          @click="dateTimePickerObj.pickerShow('check')"
          :rules="[{ required: true, message: '请选择校验停机时间' }]"
        />
      </van-cell-group>
      <van-cell-group title="颗粒物测量仪">
        <van-field
          v-model="particulateDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="particulateParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="烟气分析仪">
        <van-field
          v-model="smokeDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="smokeParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="烟气参数测试仪">
        <van-field
          v-model="parameterDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="parameterParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="加热采样装置(含自控温气体伴热管)">
        <van-field
          v-model="samplingDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="samplingParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="气体制冷装置">
        <van-field
          v-model="gasDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="gasParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="数据采集与处理控制部分">
        <van-field
          v-model="collectionDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="collectionParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="空压机及反吹风机部分">
        <van-field
          v-model="airDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="airParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>
      <van-cell-group title="采样泵、蠕动泵、控制阀部分">
        <van-field
          v-model="pumpDescribe"
          :colon="true"
          label="检修情况描述"
          type="textarea"
          placeholder="请输入检修情况描述"
          :rules="[{ required: true, message: '请输入检修情况描述' }]"
        />
        <van-field
          v-model="pumpParts"
          :colon="true"
          label="更换部件"
          placeholder="请输入更换部件"
          :rules="[{ required: true, message: '请输入更换部件' }]"
        />
      </van-cell-group>

      <van-field
        v-model="situation"
        :colon="true"
        label="维修后系统运行情况"
        placeholder="请输入维修后系统运行情况"
        :rules="[{ required: true, message: '请输入维修后系统运行情况' }]"
      />
      <van-field
        v-model="station"
        :colon="true"
        label="站房清理"
        placeholder="请输入站房清理"
        :rules="[{ required: true, message: '请输入站房清理' }]"
      />
      <van-field
        v-model="summary"
        :colon="true"
        type="textarea"
        label="停机检修情况总结"
        placeholder="请输入停机检修情况总结"
        :rules="[{ required: true, message: '请输入停机检修情况总结' }]"
      />
      <van-field
        v-model="remark"
        :colon="true"
        type="textarea"
        label="备注"
        placeholder="请输入备注"
        :rules="[{ required: true, message: '请输入备注' }]"
      />
      <van-field
        v-model="personnel"
        :colon="true"
        label="检修人"
        placeholder="请输入检修人"
        :rules="[{ required: true, message: '请输入检修人' }]"
      />
      <van-field
        readonly
        :colon="true"
        clickable
        name="datetimePicker"
        :value="usageTime"
        label="离开时间"
        placeholder="点击选择时间"
        @click="dateTimePickerObj.pickerShow('usage')"
        :rules="[{ required: true, message: '请选择离开时间' }]"
      />
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="datePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickerObj.value"
        @confirm="datePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dateTimePickerObj: {
        value: new Date(),
        type: "",
        isShow: false,
        pickerShow: (type) => {
          this.dateTimePickerObj.isShow = true;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "happen":
              if (!this.happenTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.happenTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "check":
              if (!this.checkTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.checkTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "usage":
              this.dateTimePickerObj.isShow = true;
              if (!this.usageTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.usageTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "happen":
              this.happenTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "check":
              this.checkTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "usage":
              this.usageTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
          }
        },
      },
      datePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.datePickerObj.isShow = false;
          if (!this.time) {
            this.datePickerObj.value = new Date();
          } else {
            this.datePickerObj.value = new Date(
              this.$moment(this.time).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.datePickerObj.isShow = false;
          this.time = this.$moment(this.datePickerObj.value).format(
            "YYYY-MM-DD"
          );
        },
      },
      enterpriseName: "",
      time: this.$moment().format("YYYY-MM-DD"),
      poiName: "",
      checkTime: "",

      particulateDescribe: "",
      particulateParts: "",
      smokeDescribe: "",
      smokeParts: "",
      parameterDescribe: "",
      parameterParts: "",
      samplingDescribe: "",
      samplingParts: "",
      gasDescribe: "",
      gasParts: "",
      collectionDescribe: "",
      collectionParts: "",
      airDescribe: "",
      airParts: "",
      pumpDescribe: "",
      pumpParts: "",
      situation: "",
      station: "",
      summary: "",
      remark: "",
      personnel: "",
      usageTime: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      const obj = {
        enterpriseName: this.$route.query.enterpriseName,
        time: this.time,
        poiName: this.$route.query.pointName,
        checkTime: this.checkTime,

        particulateDescribe: this.particulateDescribe,
        particulateParts: this.particulateParts,
        smokeDescribe: this.smokeDescribe,
        smokeParts: this.smokeParts,
        parameterDescribe: this.parameterDescribe,
        parameterParts: this.parameterParts,
        samplingDescribe: this.samplingDescribe,
        samplingParts: this.samplingParts,
        gasDescribe: this.gasDescribe,
        gasParts: this.gasParts,
        collectionDescribe: this.collectionDescribe,
        collectionParts: this.collectionParts,
        airDescribe: this.airDescribe,
        airParts: this.airParts,
        pumpDescribe: this.pumpDescribe,
        pumpParts: this.pumpParts,
        situation: this.situation,
        station: this.station,
        summary: this.summary,
        remark: this.remark,
        personnel: this.personnel,
        usageTime: this.usageTime,
      };
      const content = JSON.stringify(obj);
      const data = {
        // content: content,
        // enterpriseId: "1262280304815538178",
        // pointId: "1273432174032388211",
        // userId: "1",
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 8,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
