<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="日常巡检维护记录表"
      fixed
      left-arrow
      @click-left="goBack"
      style="z-index: 99"
    >
      <!-- <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template> -->
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称："
          placeholder="请输入企业名称"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="dateTime"
          label="巡检日期："
          placeholder="点击选择巡检日期"
          @click="dataPickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择巡检日期' }]"
        />
        <van-field
          v-model="manufacturer"
          label="气态污染物CEMS生产商："
          placeholder="请输入气态污染物CEMS生产商"
          :rules="[{ required: true, message: '请输入气态污染物CEMS生产商' }]"
        />
        <van-field
          v-model="specificationModel"
          label="气态污染物CEMS规格型号："
          placeholder="请输入气态污染物CEMS规格型号"
          :rules="[{ required: true, message: '请输入气态污染物CEMS规格型号' }]"
        />
        <van-field
          v-model="matterProducers"
          label="颗粒物CEMS生产商："
          placeholder="请输入颗粒物CEMS生产商"
          :rules="[{ required: true, message: '请输入颗粒物CEMS生产商' }]"
        />
        <van-field
          v-model="matterModel"
          label="颗粒物CEMS规格型号："
          placeholder="请输入颗粒物CEMS规格型号"
          :rules="[{ required: true, message: '请输入颗粒物CEMS规格型号' }]"
        />
        <van-field
          v-model="installationSite"
          label="安装地点："
          placeholder="请输入安装地点"
          :rules="[{ required: true, message: '请输入安装地点' }]"
        />
        <van-field
          v-model="maintenanceUnit"
          label="维护单位："
          placeholder="请输入维护单位"
          :rules="[{ required: true, message: '请输入维护单位' }]"
        />
      </van-cell-group>
      <van-cell-group title="设备巡检内容、情况、及处理情况说明">
        <van-tabs v-model="active">
          <van-tab title="烟气监测系统">
            <van-cell-group title="探头滤芯、采样管、伴热管是否堵塞">
              <van-field
                v-model="list.blockingInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.blockingTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.blockingProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group
              title="采样探头反吹是否正常，电磁阀、反吹气源是否正常"
            >
              <van-field
                v-model="list.samplingInspection"
                label="巡检部件情况说明："
                type="textarea"
                placeholder="请输入巡检部件情况说明"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.samplingTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.samplingProcessing"
                label="处理后结果说明："
                type="textarea"
                placeholder="请输入处理后结果说明"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="采样泵、致冷器、过滤器、采样流量是否正常">
              <van-field
                v-model="list.pumpInspection"
                label="巡检部件情况说明："
                type="textarea"
                placeholder="请输入巡检部件情况说明"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.pumpTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.pumpProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group
              title="直接烟气分析仪的净化装置管路、风机、过滤器、风量"
            >
              <van-field
                v-model="list.analyzerInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.analyzerTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.analyzerProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="吸附剂、干燥剂是否过期">
              <van-field
                v-model="list.adsorbentInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.adsorbentTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.adsorbentProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group
              title="烟气监测数据是否正常，分析仪（直抽式）校准是否正常"
            >
              <van-field
                v-model="list.monitoringInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.monitoringTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.monitoringProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="list.standardInspection"
                label="标气的浓度、有效期时间、剩余压力："
                placeholder="请输入标气的浓度、有效期时间、剩余压力"
                :rules="[
                  {
                    required: true,
                    message: '请输入标气的浓度、有效期时间、剩余压力',
                  },
                ]"
              />
            </van-cell-group>
            <!-- <van-cell-group title="标气的浓度、有效期时间、剩余压力">
              <van-field
                v-model="standardInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="standardTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="standardProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group> -->
          </van-tab>
          <van-tab title="烟尘监测系统">
            <van-cell-group title="鼓风机、风管、空气过滤器等部件工作是否正常">
              <van-field
                v-model="list.partsInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.partsTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.partsProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="穿法烟尘分析仪的光点是否偏移">
              <van-field
                v-model="list.deviationInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.deviationTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.deviationProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="烟尘监测数据是否正常">
              <van-field
                v-model="list.smokeInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.smoketTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.smokeProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="流速监测系统">
            <van-cell-group title="检查皮托管的反吹管路、控制阀等否正常">
              <van-field
                v-model="list.pitottubeInspection"
                label="巡检部件情况说明："
                type="textarea"
                placeholder="请输入巡检部件情况说明"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.pitottubeTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.pitottubeProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group
              title="超声波法：检查鼓风机、软管、过滤器等部件是否正常"
            >
              <van-field
                v-model="list.ultrasonicInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.ultrasonicTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.ultrasonicProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="监测流速值是否正常">
              <van-field
                v-model="list.flowrateInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.flowrateTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.flowrateProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="其它烟气监测参数">
            <van-cell-group title="温度测量值是否正常">
              <van-field
                v-model="list.temperatureInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.temperatureTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.temperatureProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="湿度测量值是否正常">
              <van-field
                v-model="list.humidityInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.humidityTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.humidityProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="氧量测量值是否正常">
              <van-field
                v-model="list.oxygenInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.oxygenTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.oxygenProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="数据采集传输装置">
            <van-cell-group title="数采仪工作是否正常">
              <van-field
                v-model="list.acquisitionInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.acquisitiontreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.acquisitionprocessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="各通讯线的连接是否松动">
              <van-field
                v-model="list.communicationInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.communicationTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.communicationProcessing"
                label="处理后结果说明："
                type="textarea"
                placeholder="请输入处理后结果说明"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group
              title="分析仪、工控机、数据采集传输仪上的数据是否一致"
            >
              <van-field
                v-model="list.industrialInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.industrialTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.industrialProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="其它辅助设备">
            <van-cell-group title="反吹气源是否正常">
              <van-field
                v-model="list.blowbackInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.blowbackTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.blowbackProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="室内的温湿度、空调是否正常">
              <van-field
                v-model="list.indoorInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.indoorTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.indoorProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="监测站房的门窗是否密封">
              <van-field
                v-model="list.stationInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.stationTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.stationProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
            <van-cell-group title="站房的清洁卫生">
              <van-field
                v-model="list.sanitaryInspection"
                label="巡检部件情况说明："
                placeholder="请输入巡检部件情况说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入巡检部件情况说明' }]"
              />
              <van-field
                v-model="list.sanitaryTreatment"
                label="处理情况："
                placeholder="请输入处理情况"
                :rules="[{ required: true, message: '请输入处理情况' }]"
              />
              <van-field
                v-model="list.sanitaryProcessing"
                label="处理后结果说明："
                placeholder="请输入处理后结果说明"
                type="textarea"
                :rules="[{ required: true, message: '请输入处理后结果说明' }]"
              />
            </van-cell-group>
          </van-tab>
        </van-tabs>
      </van-cell-group>
      <van-cell-group title=" ">
        <van-field
          v-model="abnormalRecord"
          label="异常情况处理记录："
          placeholder="请输入异常情况处理记录"
          :rules="[{ required: true, message: '请输入异常情况处理记录' }]"
        />
        <van-field
          v-model="inspector"
          label="本次巡检人："
          placeholder="请输入本次巡检人"
          :rules="[{ required: true, message: '请输入本次巡检人' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="inspectionTime"
          label="本次巡检时间"
          placeholder="点击选择本次巡检时间"
          :colon="true"
          @click="dateTimePickerObj.pickerShow"
          :rules="[{ required: true, message: '请输入本次巡检时间' }]"
        />
        <!-- <van-field
          v-model="inspectionTime"
          label="本次巡检时间："
          placeholder="请输入本次巡检时间"
          :rules="[{ required: true, message: '请输入本次巡检时间' }]"
        /> -->
        <van-field
          v-model="personName"
          label="企业负责人（签字）："
          placeholder="请输入企业负责人（签字）"
          :rules="[{ required: true, message: '请输入企业负责人（签字）' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="dataPickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="dataPickerObj.value"
        @confirm="dataPickerObj.pickerConfirm"
        @cancel="dataPickerObj.isShow = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
// import { Dialog } from "vant";
export default {
  components: {},
  props: {},
  data() {
    return {
      active: " ",
      dateTimePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.dateTimePickerObj.isShow = false;
          if (!this.inspectionTime) {
            this.dateTimePickerObj.value = new Date();
          } else {
            this.dateTimePickerObj.value = new Date(
              this.$moment(this.inspectionTime).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          this.inspectionTime = this.$moment(
            this.dateTimePickerObj.value
          ).format("YYYY-MM-DD HH:mm");
        },
      },
      dataPickerObj: {
        isShow: false,
        value: new Date(),
        pickerShow: () => {
          this.dataPickerObj.isShow = false;
          if (!this.dateTime) {
            this.dataPickerObj.value = new Date();
          } else {
            this.dataPickerObj.value = new Date(
              this.$moment(this.dateTime).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTime = this.$moment(this.dataPickerObj.value).format(
            "YYYY-MM-DD"
          );
          this.dataPickerObj.isShow = false;
        },
      },

      // 基本信息

      dateTime: this.$moment().format("YYYY-MM-DD"),

      pointName: "",
      manufacturer: "",
      specificationModel: "",
      matterProducers: "",
      matterModel: "",
      installationSite: "",
      maintenanceUnit: "",

      // 烟气监测系统
      list: {
        blockingInspection: "",
        blockingTreatment: "",
        blockingProcessing: "",
        samplingInspection: "",
        samplingTreatment: "",
        samplingProcessing: "",
        pumpInspection: "",
        pumpTreatment: "",
        pumpProcessing: "",
        analyzerInspection: "",
        analyzerTreatment: "",
        analyzerProcessing: "",
        adsorbentInspection: "",
        adsorbentTreatment: "",
        adsorbentProcessing: "",
        monitoringInspection: "",
        monitoringTreatment: "",
        monitoringProcessing: "",
        standardInspection: "",

        // 烟尘监测系统

        partsInspection: "",

        partsTreatment: "",
        partsProcessing: "",
        deviationInspection: "",
        deviationTreatment: "",
        deviationProcessing: "",
        smokeInspection: "",
        smoketTreatment: "",
        smokeProcessing: "",

        // 流速监测系统
        pitottubeInspection: "",
        pitottubeTreatment: "",
        pitottubeProcessing: "",
        ultrasonicInspection: "",
        ultrasonicTreatment: "",
        ultrasonicProcessing: "",
        flowrateInspection: "",
        flowrateTreatment: "",
        flowrateProcessing: "",

        // 其它烟气监测参数
        temperatureInspection: "",
        temperatureTreatment: "",
        temperatureProcessing: "",
        humidityInspection: "",
        humidityTreatment: "",
        humidityProcessing: "",
        oxygenInspection: "",
        oxygenTreatment: "",
        oxygenProcessing: "",

        // 数据采集传输装置
        acquisitionInspection: "",
        acquisitiontreatment: "",
        acquisitionprocessing: "",
        communicationInspection: "",
        communicationTreatment: "",
        communicationProcessing: "",
        industrialInspection: "",
        industrialTreatment: "",
        industrialProcessing: "",

        // 其它辅助设备
        blowbackInspection: "",
        blowbackTreatment: "",
        blowbackProcessing: "",
        indoorInspection: "",
        indoorTreatment: "",
        indoorProcessing: "",
        stationInspection: "",
        stationTreatment: "",
        stationProcessing: "",
        sanitaryInspection: "",
        sanitaryTreatment: "",
        sanitaryProcessing: "",
      },

      //
      abnormalRecord: "",
      inspector: "",
      inspectionTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      personName: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      const obj = {
        dateTime: this.dateTime,
        pointName: this.$route.query.enterpriseName,
        manufacturer: this.manufacturer,
        specificationModel: this.specificationModel,
        matterProducers: this.matterProducers,
        matterModel: this.matterModel,
        installationSite: this.installationSite,
        maintenanceUnit: this.maintenanceUnit,
        list: this.list,

        // blockingInspection: this.blockingInspection,
        // blockingTreatment: this.blockingTreatment,
        // blockingProcessing: this.blockingProcessing,

        // samplingInspection: this.samplingInspection,
        // samplingTreatment: this.samplingTreatment,
        // samplingProcessing: this.samplingProcessing,

        // pumpInspection: this.pumpInspection,
        // pumpTreatment: this.pumpTreatment,
        // pumpProcessing: this.pumpProcessing,

        // analyzerInspection: this.analyzerInspection,
        // analyzerTreatment: this.analyzerTreatment,
        // analyzerProcessing: this.analyzerProcessing,

        // adsorbentInspection: this.adsorbentInspection,
        // adsorbentTreatment: this.adsorbentTreatment,
        // adsorbentProcessing: this.adsorbentProcessing,

        // monitoringInspection: this.monitoringInspection,
        // monitoringTreatment: this.monitoringTreatment,
        // monitoringProcessing: this.monitoringProcessing,

        // standardInspection: this.standardInspection,

        // partsInspection: this.partsInspection,
        // partsTreatment: this.partsTreatment,
        // partsProcessing: this.partsProcessing,
        // deviationInspection: this.deviationInspection,
        // deviationTreatment: this.deviationTreatment,
        // deviationProcessing: this.deviationProcessing,
        // smokeInspection: this.smokeInspection,
        // smoketTreatment: this.smoketTreatment,
        // smokeProcessing: this.smokeProcessing,

        // pitottubeInspection: this.pitottubeInspection,
        // pitottubeTreatment: this.pitottubeTreatment,
        // pitottubeProcessing: this.pitottubeProcessing,

        // ultrasonicInspection: this.ultrasonicInspection,
        // ultrasonicTreatment: this.ultrasonicTreatment,
        // ultrasonicProcessing: this.ultrasonicProcessing,

        // flowrateInspection: this.flowrateInspection,
        // flowrateTreatment: this.flowrateTreatment,
        // flowrateProcessing: this.flowrateProcessing,

        // temperatureInspection: this.temperatureInspection,
        // temperatureTreatment: this.temperatureTreatment,
        // temperatureProcessing: this.temperatureProcessing,
        // humidityInspection: this.humidityInspection,
        // humidityTreatment: this.humidityTreatment,
        // humidityProcessing: this.humidityProcessing,
        // oxygenInspection: this.oxygenInspection,
        // oxygenTreatment: this.oxygenTreatment,
        // oxygenProcessing: this.oxygenProcessing,

        // acquisitionInspection: this.acquisitionInspection,
        // acquisitiontreatment: this.acquisitiontreatment,
        // acquisitionprocessing: this.acquisitionprocessing,
        // communicationInspection: this.communicationInspection,
        // communicationTreatment: this.communicationTreatment,
        // communicationProcessing: this.communicationProcessing,
        // industrialInspection: this.industrialInspection,
        // industrialTreatment: this.industrialTreatment,
        // industrialProcessing: this.industrialProcessing,

        // blowbackInspection: this.blowbackInspection,
        // blowbackTreatment: this.blowbackTreatment,
        // blowbackProcessing: this.blowbackProcessing,
        // indoorInspection: this.indoorInspection,
        // indoorTreatment: this.indoorTreatment,
        // indoorProcessing: this.indoorProcessing,
        // stationInspection: this.stationInspection,
        // stationTreatment: this.stationTreatment,
        // stationProcessing: this.stationProcessing,
        // sanitaryInspection: this.sanitaryInspection,
        // sanitaryTreatment: this.sanitaryTreatment,
        // sanitaryProcessing: this.sanitaryProcessing,

        abnormalRecord: this.abnormalRecord,
        inspector: this.inspector,
        inspectionTime: this.inspectionTime,
        personName: this.personName,
      };
      for (const key in this.list) {
        if (this.list[key] === "") {
          Toast.fail("请填写完整的信息");
          return;
        }
      }
      const content = JSON.stringify(obj);
      console.log(obj);
      const data = {
        // content: content,
        // enterpriseId: "1262280304815538178",
        // pointId: "1273432174032388211",
        // userId: "1",
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 11,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.van-tabs__content {
  .van-cell-group__title {
    background-color: #e9eef2;
  }
}
</style>
