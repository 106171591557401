<template>
  <div class="operation" v-padding:top="46">
    <van-nav-bar title="运维" fixed> </van-nav-bar>
    <van-sticky :offset-top="46">
      <div class="search">
        <van-field
          v-model="enterpriseName"
          placeholder="搜索企业名称"
          right-icon="search"
          @click-right-icon="onSearch"
        />
      </div>
    </van-sticky>
    <van-cell-group v-for="(item, index) in pointList" :key="index">
      <van-cell :label="item.poiName" is-link @click="toOperationDetail(item)">
        <span class="cell-title" slot="title">{{ item.enterpriseName }}</span>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pointList: [], // 站点列表
      enterpriseName: "", // 企业名称
    };
  },
  mounted() {
    this.getOperationSite();
  },
  methods: {
    // 跳转运维详情页面
    toOperationDetail(item) {
      this.$router.push({
        name: "OperationDetail",
        query: {
          enterpriseId: item.enterpriseId,
          pointId: item.id,
          enterpriseName: item.enterpriseName,
          poiName: item.poiName,
        },
      });
    },
    // 查询
    onSearch() {
      this.current = 1;
      this.pointList = [];
      this.getOperationSite();
    },
    // 获取运维站点
    getOperationSite() {
      const data = {
        enterpriseName: this.enterpriseName,
      };
      this.$api.operation.getOperationSite(data).then((res) => {
        if (res.data.state == 0) {
          this.pointList = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
