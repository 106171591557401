<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="故障维修记录表" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          v-model="devName"
          label="设备名称"
          placeholder="请输入设备名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入设备名称' }]"
        />
        <van-field
          v-model="model"
          label="规格型号"
          :colon="true"
          placeholder="请输入规格型号"
          :rules="[{ required: true, message: '请输入规格型号' }]"
        />
        <van-field
          v-model="units"
          label="运营单位"
          :colon="true"
          placeholder="请输入运营单位"
          :rules="[{ required: true, message: '请输入运营单位' }]"
        />
        <van-field
          v-model="fault"
          label="故障情况"
          :colon="true"
          placeholder="请输入故障情况"
          type="textarea"
          :rules="[{ required: true, message: '请输入故障情况' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="happenTime"
          label="发生时间"
          placeholder="点击选择时间"
          :colon="true"
          @click="dateTimePickerObj.pickerShow('happen')"
          :rules="[{ required: true, message: '请选择发生时间' }]"
        />
        <van-field
          v-model="result"
          label="校验结果说明"
          type="textarea"
          :colon="true"
          placeholder="请输入校验结果说明"
          :rules="[{ required: true, message: '请输入校验结果说明' }]"
        />
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="checkTime"
          label="修复后使用前校验时间"
          placeholder="点击选择时间"
          @click="dateTimePickerObj.pickerShow('check')"
          :rules="[{ required: true, message: '请选择校验时间' }]"
        />
        <van-field
          v-model="maintenance"
          :colon="true"
          label="维修说明"
          type="textarea"
          placeholder="请输入维修说明"
          :rules="[{ required: true, message: '请输入维修说明' }]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="usageTime"
          label="正常投入使用时间"
          placeholder="点击选择时间"
          @click="dateTimePickerObj.pickerShow('usage')"
          :rules="[{ required: true, message: '请选择使用时间' }]"
        />
        <van-field
          v-model="personnel"
          :colon="true"
          label="维修人员"
          placeholder="请输入维修人员"
          :rules="[{ required: true, message: '请输入维修人员' }]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="time"
          label="维修日期"
          placeholder="点击选择日期"
          @click="datePickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择维修日期' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="datePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickerObj.value"
        @confirm="datePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dateTimePickerObj: {
        value: new Date(),
        type: "",
        isShow: false,
        pickerShow: (type) => {
          this.dateTimePickerObj.isShow = true;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "happen":
              if (!this.happenTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.happenTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "check":
              if (!this.checkTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.checkTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "usage":
              if (!this.usageTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.usageTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "happen":
              this.happenTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "check":
              this.checkTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "usage":
              this.usageTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
          }
        },
      },
      datePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.datePickerObj.isShow = true;
          if (!this.time) {
            this.datePickerObj.value = new Date();
          } else {
            this.datePickerObj.value = new Date(
              this.$moment(this.time).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.datePickerObj.isShow = false;
          this.time = this.$moment(this.datePickerObj.value).format(
            "YYYY-MM-DD"
          );
        },
      },
      devName: "",
      model: "",
      units: "",
      fault: "",
      happenTime: "",
      result: "",
      checkTime: "",
      maintenance: "",
      usageTime: "",
      personnel: "",
      time: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSubmit() {
      const obj = {
        devName: this.devName,
        model: this.model,
        units: this.units,
        fault: this.fault,
        happenTime: this.happenTime,
        result: this.result,
        checkTime: this.checkTime,
        maintenance: this.maintenance,
        usageTime: this.usageTime,
        personnel: this.personnel,
        time: this.time,
      };
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 3,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
