<template>
  <div class="operation operation-detail" v-padding:top="46">
    <!-- 头部 -->
    <van-nav-bar title="运维" left-arrow @click-left="goBack" fixed />
    <!-- 头部end -->
    <!-- 搜索 -->
    <van-sticky :offset-top="46">
      <div class="search">
        <van-field
          v-model="dateValue"
          placeholder="选择时间范围"
          right-icon="iconfont iconfont iconrilirichengCRM"
          @click-right-icon="onShowCalendar"
          @focus="onShowCalendar"
        />
      </div>
    </van-sticky>
    <!-- 搜索end -->
    <!-- 站点信息 -->
    <van-cell-group>
      <van-cell :label="poiName">
        <span class="cell-title" slot="title">{{ enterpriseName }}</span>
      </van-cell>
    </van-cell-group>
    <!-- 站点信息end -->
    <!-- 数据列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getDataList"
    >
      <div v-for="(item, index) in dataList" :key="index">
        <time-box :value="item.gmtModified"></time-box>
        <van-cell-group>
          <van-cell title="运维人员" :value="item.userName"> </van-cell>
          <van-cell title="联系电话" :value="item.phone"> </van-cell>
          <van-cell title="运维项目" :value="item.projectName"> </van-cell>
        </van-cell-group>
      </div>
    </van-list>
    <!-- 数据列表end -->
    <!-- 日历 -->
    <van-calendar
      type="range"
      color="#378aed"
      title="时间范围"
      :allow-same-day="true"
      :min-date="new Date(2010, 0, 1)"
      v-model="showCalendar"
      @confirm="onCalendarConfirm"
    />
    <!-- 日历end -->
  </div>
</template>

<script>
import timeBox from "@/components/common/time-box.vue";

export default {
  components: { timeBox },
  data() {
    return {
      loading: false, // 上拉加载loading
      finished: false, // 上拉加载完结loading
      showCalendar: false, // 日历显示
      value: "", //
      beginTime: "", // 开始时间
      endTime: "", // 结束时间
      dateValue: "", // 时间
      pagesize: 10, // 单页数据量
      current: 1, // 页码
      pointId: "", // 站点id
      enterpriseId: "", // 企业id
      enterpriseName: "",
      poiName: "",
      dataList: [], // 数据列表
    };
  },
  methods: {
    // 设置基本信息
    setInfo() {
      this.pointId = this.$route.query.pointId;
      this.enterpriseId = this.$route.query.enterpriseId;
      this.enterpriseName = this.$route.query.enterpriseName;
      this.poiName = this.$route.query.poiName;
    },
    // 查询
    onSearch() {
      this.current = 1;
      this.dataList = [];
      this.finished = false;
      this.getDataList();
    },
    // 日历确定事件
    onCalendarConfirm(date) {
      this.beginTime = this.$moment(date[0]).format("YYYY-MM-DD") + " 00:00:00";
      this.endTime = this.$moment(date[1]).format("YYYY-MM-DD") + " 23:59:59";
      this.dateValue =
        this.$moment(date[0]).format("YYYY-MM-DD") +
        " ~ " +
        this.$moment(date[1]).format("YYYY-MM-DD");
      this.onSearch();
      this.showCalendar = false;
    },
    // 显示日历
    onShowCalendar() {
      this.showCalendar = true;
    },
    // 查询数据
    getDataList() {
      this.loading = true;
      const data = {
        page: this.current,
        size: this.pagesize,
        enterpriseId: this.enterpriseId,
        pointId: this.pointId,
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.operation
        .getOperationData(data)
        .then((res) => {
          if (res.data.state == 0) {
            for (let i = 0; i < res.data.data.records.length; i++) {
              const element = res.data.data.records[i];
              let projectName = "";
              const projectNameArr = [];
              if (element.job1) {
                projectNameArr.push("日常巡检");
              }
              if (element.job2) {
                projectNameArr.push("标液核查");
              }
              if (element.job3) {
                projectNameArr.push("校验");
              }
              if (element.job4) {
                projectNameArr.push("校准");
              }
              if (element.job5) {
                projectNameArr.push("比对");
              }
              if (element.job6) {
                projectNameArr.push("试剂更换");
              }
              if (element.job7) {
                projectNameArr.push("标液更换");
              }
              if (element.job8) {
                projectNameArr.push("故障维修");
              }
              if (element.job9) {
                projectNameArr.push("废液收集");
              }
              for (let j = 0; j < projectNameArr.length; j++) {
                if (j >= projectNameArr.length - 1) {
                  projectName += projectNameArr[j];
                } else {
                  projectName += projectNameArr[j] + ",";
                }
              }
              element.projectName = projectName;
            }
            this.dataList.push(...res.data.data.records);
          }
          if (this.current >= res.data.data.pages) {
            this.finished = true;
          } else {
            this.current++;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.finished = true;
          console.log(err);
        });
    },
  },
  mounted() {
    this.setInfo();
    this.getDataList();
  },
};
</script>
<style lang="less" scoped></style>
