<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="标准气体更换记录表"
      fixed
      left-arrow
      @click-left="goBack"
    >
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称"
          placeholder="请输入企业名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          v-model="place"
          label="安装地点"
          placeholder="请输入安装地点"
          :colon="true"
          :rules="[{ required: true, message: '请输入安装地点' }]"
        />
        <van-field
          v-model="management"
          label="维护管理单位"
          placeholder="请输入维护管理单位"
          :colon="true"
          :rules="[{ required: true, message: '请输入维护管理单位' }]"
        />
      </van-cell-group>
      <van-cell-group v-for="(item, index) in list" :key="index">
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>记录({{ index + 1 }})</span>
            <span
              ><van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList(index)"
            /></span>
          </div>
        </template>
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="list[index].replaceTime"
          label="更换日期"
          placeholder="请选择更换日期"
          @click="timeObj.pickerShow(index)"
          :rules="[{ required: true, message: '请选择更换日期' }]"
        />
        <van-field
          v-model="list[index].substanceName"
          label="标准物质名称"
          placeholder="请输入标准物质名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入标准物质名称' }]"
        />
        <van-field
          v-model="list[index].gas"
          label="气体浓度"
          placeholder="请输入气体浓度"
          :colon="true"
          :rules="[{ required: true, message: '请输入气体浓度' }]"
        />
        <van-field
          v-model="list[index].company"
          label="单位"
          placeholder="请输入单位"
          :colon="true"
          :rules="[{ required: true, message: '请输入单位' }]"
        />
        <van-field
          v-model="list[index].number"
          label="数量"
          placeholder="请输入数量"
          :colon="true"
          :rules="[{ required: true, message: '请输入数量' }]"
        />
        <van-field
          v-model="list[index].supplier"
          label="供应商"
          placeholder="请输入供应商"
          :colon="true"
          :rules="[{ required: true, message: '请输入供应商' }]"
        />
        <van-field
          v-model="list[index].validity"
          label="有效期"
          placeholder="请输入有效期"
          :colon="true"
          :rules="[{ required: true, message: '请输入有效期' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px" id="item">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="timeObj.pickerValue"
        @confirm="timeObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      showPicker: false,
      timeObj: {
        pickerValue: new Date(),
        pickerIndex: "",
        pickerShow: (index) => {
          this.showPicker = true;
          this.timeObj.pickerIndex = index;
          if (!this.list[this.timeObj.pickerIndex].replaceTime) {
            this.timeObj.pickerValue = new Date();
          } else {
            this.timeObj.pickerValue = new Date(
              this.$moment(
                this.list[this.timeObj.pickerIndex].replaceTime
              ).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.list[this.timeObj.pickerIndex].replaceTime = this.$moment(
            this.timeObj.pickerValue
          ).format("YYYY-MM-DD");
          this.showPicker = false;
        },
      },
      name: "",
      place: "",
      management: "",
      list: [
        {
          replaceTime: "",
          substanceName: "",
          gas: "",
          company: "",
          number: "",
          supplier: "",
          validity: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 删除记录
    deleteList(index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + index + "）？",
      })
        .then(() => {
          this.list.splice(index, 1);
          if (this.list.length <= 0) {
            this.list.push({
              replaceTime: "",
              substanceName: "",
              gas: "",
              company: "",
              number: "",
              supplier: "",
              validity: "",
            });
          }
        })
        .catch(() => {});
    },
    // 添加新记录
    addList() {
      Dialog.confirm({
        title: "添加新记录",
        message: "是否添加新记录？",
      })
        .then(() => {
          this.list.push({
            replaceTime: "",
            substanceName: "",
            gas: "",
            company: "",
            number: "",
            supplier: "",
            validity: "",
          });
          this.$nextTick(function () {
            document.getElementById("item").scrollIntoView();
          });
        })
        .catch(() => {});
    },
    // 确定
    onSubmit() {
      Toast.loading("提交中...");
      const obj = {
        name: this.$route.query.enterpriseName,
        place: this.place,
        management: this.management,
        list: this.list,
      };
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 10,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
