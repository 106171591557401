<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="标样核查及校准记录表"
      fixed
      left-arrow
      @click-left="goBack"
    >
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          :colon="true"
          v-model="this.$route.query.pointName"
          label="站点名称"
          placeholder="请输入站点名称"
          :rules="[{ required: true, message: '请输入站点名称' }]"
        />
        <van-field
          :colon="true"
          v-model="devName"
          label="仪器名称"
          placeholder="请输入仪器名称"
          :rules="[{ required: true, message: '请输入仪器名称' }]"
        />
        <van-field
          :colon="true"
          v-model="model"
          label="仪器型号"
          placeholder="请输入仪器型号"
          :rules="[{ required: true, message: '请输入仪器型号' }]"
        />
        <van-field
          :colon="true"
          v-model="units"
          label="维护管理单位"
          placeholder="请输入维护管理单位"
          :rules="[{ required: true, message: '请输入维护管理单位' }]"
        />
      </van-cell-group>
      <van-cell-group v-for="(item, index) in list" :key="index">
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>记录({{ index + 1 }})</span>
            <span
              ><van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList(index)"
            /></span>
          </div>
        </template>
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="list[index].checkTime"
          label="核查时间"
          placeholder="请选择核查时间"
          @click="dateTimePickerObj.pickerShow(index, 'check')"
          :rules="[{ required: true, message: '选择核查时间' }]"
        />
        <van-field
          :colon="true"
          v-model="list[index].concentration"
          label="标液浓度（mg/L）"
          placeholder="请输入标液浓度"
          :rules="[{ required: true, message: '请输入标液浓度' }]"
        />
        <van-field
          :colon="true"
          v-model="list[index].result"
          label="核查结果（mg/L）"
          placeholder="请输入核查结果"
          :rules="[{ required: true, message: '请输入核查结果' }]"
        />
        <van-field
          :colon="true"
          v-model="list[index].error"
          label="相对误差（%）"
          placeholder="请输入相对误差"
          :rules="[{ required: true, message: '请输入相对误差' }]"
        />
        <van-cell title="是否合格:">
          <van-radio-group
            v-model="list[index].isQualified"
            direction="horizontal"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </van-cell>
        <van-field
          :colon="true"
          readonly
          clickable
          name="datetimePicker"
          :value="list[index].calibrationTime"
          label="校准时间"
          placeholder="请选择校准时间"
          @click="dateTimePickerObj.pickerShow(index, 'calibration')"
          :rules="[{ required: true, message: '选择校准时间' }]"
        />
        <van-cell title="是否通过">
          <van-radio-group v-model="list[index].isPass" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
      <van-cell-group title="结果记录">
        <van-field
          :colon="true"
          v-model="exception"
          label="异常情况描述"
          type="textarea"
          placeholder="请输入异常情况描述"
          :rules="[{ required: true, message: '请输入异常情况描述' }]"
        />
        <van-field
          :colon="true"
          v-model="reasonAndMeasures"
          label="原因分析及采取措施"
          type="textarea"
          placeholder="请输入原因分析及采取措施"
          :rules="[{ required: true, message: '请输入原因分析及采取措施' }]"
        />
        <van-field
          :colon="true"
          v-model="resultAndParts"
          label="处理结果及器件更换"
          type="textarea"
          placeholder="请输入处理结果及器件更换"
          :rules="[{ required: true, message: '请输入处理结果及器件更换' }]"
        />
      </van-cell-group>
      <van-field
        :colon="true"
        v-model="personnel"
        label="实施人"
        placeholder="请输入实施人"
        :rules="[{ required: true, message: '请输入实施人' }]"
      />
      <van-cell-group title="核查审批">
        <van-field
          :colon="true"
          v-model="autograph"
          label="签字"
          placeholder="请输入签字"
          :rules="[{ required: true, message: '请输入签字' }]"
        />
        <van-field
          :colon="true"
          readonly
          clickable
          name="datetimePicker"
          :value="changeTime"
          label="更换日期"
          placeholder="更换日期"
          @click="datePickerObj.pickerShow"
          :rules="[{ required: true, message: '请输入更换日期' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="datePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickerObj.value"
        @confirm="datePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dateTimePickerObj: {
        value: new Date(),
        type: "",
        index: 0,
        isShow: false,
        pickerShow: (index, type) => {
          this.dateTimePickerObj.isShow = true;
          this.dateTimePickerObj.index = index;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "check":
              if (!this.list[this.dateTimePickerObj.index].checkTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.list[this.dateTimePickerObj.index].checkTime
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "calibration":
              if (!this.list[this.dateTimePickerObj.index].calibrationTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.list[this.dateTimePickerObj.index].calibrationTime
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "check":
              this.list[this.dateTimePickerObj.index].checkTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "calibration":
              this.list[this.dateTimePickerObj.index].calibrationTime =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
          }
        },
      },
      datePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.datePickerObj.isShow = true;
          if (!this.changeTime) {
            this.datePickerObj.value = new Date();
          } else {
            this.datePickerObj.value = new Date(
              this.$moment(this.changeTime).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.datePickerObj.isShow = false;
          this.changeTime = this.$moment(this.datePickerObj.value).format(
            "YYYY-MM-DD"
          );
        },
      },
      pointName: "",
      devName: "",
      model: "",
      units: "",
      list: [
        {
          checkTime: "",
          concentration: "",
          result: "",
          error: "",
          isQualified: "1",
          calibrationTime: "",
          isPass: "1",
        },
      ],
      exception: "",
      reasonAndMeasures: "",
      resultAndParts: "",
      personnel: "",
      autograph: "",
      changeTime: "",
      index: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 删除记录
    deleteList(index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + (index + 1) + "）？",
      })
        .then(() => {
          this.list.splice(index, 1);
          if (this.list.length <= 0) {
            this.list.push({
              checkTime: "",
              concentration: "",
              result: "",
              error: "",
              isQualified: "1",
              calibrationTime: "",
              isPass: "1",
            });
          }
        })
        .catch(() => {});
    },
    // 新增记录
    addList() {
      Dialog.confirm({
        title: "添加新记录",
        message: "是否添加新记录？",
      })
        .then(() => {
          this.list.push({
            checkTime: "",
            concentration: "",
            result: "",
            error: "",
            isQualified: "1",
            calibrationTime: "",
            isPass: "1",
          });
          // this.$nextTick(function() {
          //   document.getElementById("item").scrollIntoView();
          // });
        })
        .catch(() => {});
    },
    onSubmit() {
      const obj = {
        pointName: this.$route.query.pointName,
        devName: this.devName,
        model: this.model,
        units: this.units,
        list: this.list,
        exception: this.exception,
        reasonAndMeasures: this.reasonAndMeasures,
        resultAndParts: this.resultAndParts,
        personnel: this.personnel,
        autograph: this.autograph,
        changeTime: this.changeTime,
      };
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 5,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
