<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="CEMS校验测试记录表"
      fixed
      left-arrow
      @click-left="goBack"
      style="z-index: 99"
    >
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="showList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称"
          placeholder="请输入企业名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          v-model="supplier"
          label="CEMS供应商"
          placeholder="请输入CEMS供应商"
          :colon="true"
          :rules="[{ required: true, message: '请输入CEMS供应商' }]"
        />
      </van-cell-group>
      <van-cell-group
        v-for="(item, index) in list"
        :key="index"
        :id="'item' + index"
      >
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>CEMS主要仪器型号记录({{ index + 1 }})</span>

            <span>
              <!-- <span
                style="display:inline-block;margin-left:-10px"
                v-if="index == 0"
              >
                <van-icon
                  name="add-o"
                  color="#1989fa"
                  size="18"
                  @click="addList('main', index)"/></span
              > -->
              <van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList('main', index)"
                style="margin-left: 10px"
            /></span>
          </div>
        </template>
        <van-field
          v-model="list[index].devName"
          label="仪器名称"
          placeholder="请输入仪器名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入仪器名称' }]"
        />
        <van-field
          v-model="list[index].model"
          label="仪器型号"
          placeholder="请输入仪器型号"
          :colon="true"
          :rules="[{ required: true, message: '请输入仪器型号' }]"
        />
        <van-field
          v-model="list[index].manufacturer"
          label="制造商"
          placeholder="请输入制造商"
          :colon="true"
          :rules="[{ required: true, message: '请输入制造商' }]"
        />
        <van-field
          v-model="list[index].items"
          label="测试项目"
          placeholder="请输入测试项目"
          :colon="true"
          :rules="[{ required: true, message: '请输入测试项目' }]"
        />

        <van-field
          v-model="list[index].principle"
          label="测量原理"
          placeholder="请输入测量原理"
          :colon="true"
          :rules="[{ required: true, message: '请输入测量原理' }]"
        />
      </van-cell-group>
      <van-cell-group title=" ">
        <van-field
          v-model="address"
          label="CEMS安装地址"
          placeholder="请输入CEMS安装地址"
          :colon="true"
          :rules="[{ required: true, message: '请输入CEMS安装地址' }]"
        />
        <van-field
          v-model="company"
          label="维护管理单位"
          placeholder="请输入维护管理单位"
          :colon="true"
          :rules="[{ required: true, message: '请输入维护管理单位' }]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="time"
          label="本次校验日期"
          placeholder="点击选择日期"
          @click="datePickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择本次校验日期' }]"
        />
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="dateTime"
          label="上次校验日期"
          placeholder="请选择上次校验日期"
          @click="timeObj.pickerShow"
          :rules="[{ required: true, message: '请选择上次校验日期' }]"
        />
      </van-cell-group>
      <van-cell-group title=" ">
        <van-tabs v-model="active">
          <van-tab title="颗粒物校验">
            <van-cell-group v-for="(item, index) in keList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>颗粒物校验记录({{ index + 1 }})</span>

                  <span>
                    <!--<span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('matter')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('matter', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="keList[index].checkTime"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'matter')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="keList[index].reference"
                label="参比方法测定值(mg/m3)"
                placeholder="请输入参比方法测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测定值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="keList[index].measured"
                label="CEMS测定值(mg/m3)"
                placeholder="请输入CEMS测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测定值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluation"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationCems"
                label="CEMS测定平均值"
                placeholder="请输入CEMS测定平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测定平均值' }]"
              />
              <!-- <van-cell title="误差类型：">
                <van-radio-group v-model="allList.error" direction="horizontal">
                  <van-radio name="1">相对</van-radio>
                  <van-radio name="2">绝对</van-radio>
                </van-radio-group>
              </van-cell> -->
              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.error"
                    direction="horizontal"
                  >
                    <van-radio name="1">相对</van-radio>
                    <van-radio name="2">绝对</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="allList.errorValue"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterion"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.result"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="SO2校验">
            <van-cell-group v-for="(item, index) in soList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>SO2校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkSo')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkSo', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="soList[index].checkTimeSo"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkSo')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="soList[index].referenceSo"
                label="参比方法测量值(mg/m3)"
                placeholder="请输入参比方法测量值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测量值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="soList[index].measuredSo"
                label="CEMS测量值(mg/m3)"
                placeholder="请输入CEMS测量值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测量值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationSo"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationSoCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />
              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.errorSo"
                    direction="horizontal"
                  >
                    <van-radio name="1">相对</van-radio>
                    <van-radio name="2">绝对</van-radio>
                    <van-radio name="0">相对准确度</van-radio>
                  </van-radio-group>
                </template>
              </van-field>

              <van-field
                v-model="allList.errorValueSo"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterionSo"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultSo"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="NOx校验">
            <van-cell-group v-for="(item, index) in noList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>NOx校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkNo')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkNo', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="noList[index].checkTimeNo"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkNo')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="noList[index].referenceNo"
                label="参比方法测量值(mg/m3)"
                placeholder="请输入参比方法测量值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测量值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="noList[index].measuredNo"
                label="CEMS测量值(mg/m3)"
                placeholder="请输入CEMS测量值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测量值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationNo"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationNoCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />

              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.errorNo"
                    direction="horizontal"
                  >
                    <van-radio name="1">相对</van-radio>
                    <van-radio name="2">绝对</van-radio>
                    <van-radio name="0">相对准确度</van-radio>
                  </van-radio-group>
                </template>
              </van-field>

              <!-- <van-cell title="误差类型：">
                <van-radio-group v-model="allList.errorNo">
                  <van-radio name="1">相对</van-radio>
                  <van-radio name="2">绝对</van-radio>
                  <van-radio name="0">相对</van-radio>
                </van-radio-group>
              </van-cell> -->

              <van-field
                v-model="allList.errorValueNo"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterionNo"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultNo"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              /> </van-cell-group
          ></van-tab>
          <van-tab title="O2校验">
            <van-cell-group v-for="(item, index) in oList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>O2校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkO')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkO', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="oList[index].checkTimeO"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkO')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="oList[index].referenceO"
                label="参比方法测定值(mg/m3)"
                placeholder="请输入参比方法测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测定值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="oList[index].measuredO"
                label="CEMS测定值(mg/m3)"
                placeholder="请输入CEMS测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测定值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationO"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationOCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />

              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.errorO"
                    direction="horizontal"
                  >
                    <van-radio name="1">绝对</van-radio>
                    <!-- <van-radio name="2">绝对</van-radio> -->
                    <van-radio name="0">相对准确度</van-radio>
                  </van-radio-group>
                </template>
              </van-field>

              <!-- <van-cell title="误差类型：">
                <van-radio-group v-model="allList.errorO">
                <van-radio name="1" icon-size="14px">相对</van-radio> -->
              <!-- <van-radio name="1">绝对</van-radio>
                  <van-radio name="0">相对准确度</van-radio>
                </van-radio-group>
              </van-cell> -->

              <van-field
                v-model="allList.errorValueO"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterionO"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultO"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="流速校验">
            <van-cell-group v-for="(item, index) in liuList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>流速校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkLiu')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkLiu', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="liuList[index].checkTimeLiu"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkLiu')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="liuList[index].referenceLiu"
                label="参比方法测定值(mg/m3)"
                placeholder="请输入参比方法测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测定值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="liuList[index].measuredLiu"
                label="CEMS测定值(mg/m3)"
                placeholder="请输入CEMS测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测定值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationLiu"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationLiuCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />
              <!-- <van-cell title="误差类型：">
                <van-radio-group
                  v-model="allList.errorLiu"
                  direction="horizontal"
                >
                  <van-radio name="1">相对</van-radio>
                  <van-radio name="2">绝对</van-radio>
                </van-radio-group>
              </van-cell> -->
              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.errorLiu"
                    direction="horizontal"
                  >
                    <van-radio name="1">相对</van-radio>
                    <van-radio name="2">绝对</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="allList.errorValueLiu"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterionLiu"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultLiu"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="烟温校验">
            <van-cell-group v-for="(item, index) in yanList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>烟温校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkYan')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkYan', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="yanList[index].checkTimeYan"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkYan')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="yanList[index].referenceYan"
                label="参比方法测定值(mg/m3)"
                placeholder="请输入参比方法测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测定值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="yanList[index].measuredYan"
                label="CEMS测定值(mg/m3)"
                placeholder="请输入CEMS测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测定值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationYan"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationYanCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />
              <!-- <van-cell title="误差类型：">
                <van-radio-group v-model="errorYan" direction="horizontal">
                  <van-radio name="1">相对</van-radio>
                  <van-radio name="2">绝对</van-radio>
                </van-radio-group>
              </van-cell> -->
              <van-field
                v-model="allList.errorValueYan"
                label="绝对误差值(℃)"
                placeholder="请输入绝对误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入绝对误差值' }]"
              />
              <van-field
                v-model="allList.criterionYan"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultYan"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="湿度校验">
            <van-cell-group v-for="(item, index) in shiList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>湿度校验记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkShi')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkShi', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                readonly
                :colon="true"
                clickable
                name="datetimePicker"
                :value="shiList[index].checkTimeShi"
                label="监测时间"
                placeholder="请选择监测时间"
                @click="dateTimePickerObj.pickerShow(index, 'checkShi')"
                :rules="[{ required: true, message: '选择监测时间' }]"
              />
              <van-field
                :colon="true"
                v-model="shiList[index].referenceShi"
                label="参比方法测定值(mg/m3)"
                placeholder="请输入参比方法测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入参比方法测定值(mg/m3)' },
                ]"
              />
              <van-field
                :colon="true"
                v-model="shiList[index].measuredShi"
                label="CEMS测定值(mg/m3)"
                placeholder="请输入CEMS测定值(mg/m3)"
                :rules="[
                  { required: true, message: '请输入CEMS测定值(mg/m3)' },
                ]"
              />
            </van-cell-group>
            <van-cell-group title=" ">
              <van-field
                v-model="allList.evaluationShi"
                label="参比方法平均值"
                placeholder="请输入参比方法平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入参比方法平均值' }]"
              />
              <van-field
                v-model="allList.evaluationShiCems"
                label="CEMS测量平均值"
                placeholder="请输入CEMS测量平均值"
                :colon="true"
                :rules="[{ required: true, message: '请输入CEMS测量平均值' }]"
              />
              <!-- <van-cell title="误差类型：">
                <van-radio-group
                  v-model="allList.errorShi"
                  direction="horizontal"
                >
                  <van-radio name="1">相对</van-radio>
                  <van-radio name="2">绝对</van-radio>
                </van-radio-group>
              </van-cell> -->

              <van-field name="radio" label="误差类型:">
                <template #input>
                  <van-radio-group
                    v-model="allList.errorShi"
                    direction="horizontal"
                  >
                    <van-radio name="1">相对</van-radio>
                    <van-radio name="2">绝对</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="allList.errorValueShi"
                label="误差值"
                placeholder="请输入误差值"
                :colon="true"
                :rules="[{ required: true, message: '请输入误差值' }]"
              />
              <van-field
                v-model="allList.criterionShi"
                label="评价标准"
                placeholder="请输入评价标准"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价标准' }]"
              />
              <van-field
                v-model="allList.resultShi"
                label="评价结果"
                placeholder="请输入评价结果"
                :colon="true"
                :rules="[{ required: true, message: '请输入评价结果' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="校验结论">
            <van-cell-group title="校验结论">
              <van-field
                v-model="allList.explainBefore"
                label="如校验合格前对系统进行过处理、调整、参数修改，请说明"
                placeholder="请输入如校验合格前对系统进行过处理、调整、参数修改，请说明"
                type="textarea"
                :colon="true"
                :rules="[
                  {
                    required: true,
                    message:
                      '请输入如校验合格前对系统进行过处理、调整、参数修改，请说明',
                  },
                ]"
              />

              <van-field
                v-model="allList.explainAfter"
                label="如校验后，烟尘分析仪、流速仪的原校正系统改动，请说明"
                placeholder="请输入如校验后，烟尘分析仪、流速仪的原校正系统改动，请说明"
                type="textarea"
                :colon="true"
                :rules="[
                  {
                    required: true,
                    message:
                      '请输入如校验后，烟尘分析仪、流速仪的原校正系统改动，请说明',
                  },
                ]"
              />
              <!-- <van-cell title="总体校验是否合格">
                <van-radio-group
                  v-model="allList.qualified"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </van-cell> -->
              <van-field name="radio" label="总体校验是否合格:">
                <template #input>
                  <van-radio-group
                    v-model="allList.qualified"
                    direction="horizontal"
                  >
                    <van-radio name="1">是</van-radio>
                    <van-radio name="2">否</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-cell-group>
          </van-tab>
          <van-tab title="标准气体">
            <van-cell-group v-for="(item, index) in gasList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>标准气体记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkGas')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkGas', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                :colon="true"
                v-model="gasList[index].gasName"
                label="校准气体名称"
                placeholder="请输入校准气体名称"
                :rules="[{ required: true, message: '请输入校准气体名称' }]"
              />
              <van-field
                :colon="true"
                v-model="gasList[index].concentration"
                label="浓度值"
                placeholder="请输入浓度值"
                :rules="[{ required: true, message: '请输入浓度值' }]"
              />
              <van-field
                :colon="true"
                v-model="gasList[index].manufacturer"
                label="生产厂商名称"
                placeholder="请输入生产厂商名称"
                :rules="[{ required: true, message: '请输入生产厂商名称' }]"
              />
            </van-cell-group>
          </van-tab>
          <van-tab title="参比方法测试设备">
            <van-cell-group v-for="(item, index) in canList" :key="index">
              <template #title>
                <div style="display: flex; justify-content: space-between">
                  <span>参比方法测试设备记录({{ index + 1 }})</span>

                  <span>
                    <!-- <span v-if="index == 0">
                      <van-icon
                        name="add-o"
                        color="#1989fa"
                        size="18"
                        @click="addList('checkCan')"/></span
                    > -->
                    <van-icon
                      name="delete"
                      color="#ee0a24"
                      size="18"
                      @click="deleteList('checkCan', index)"
                      style="margin-left: 10px"
                  /></span>
                </div>
              </template>
              <van-field
                :colon="true"
                v-model="canList[index].testProject"
                label="测试项目"
                placeholder="请输入测试项目"
                :rules="[{ required: true, message: '请输入测试项目' }]"
              />
              <van-field
                :colon="true"
                v-model="canList[index].testEquipment"
                label="测试设备生产商"
                placeholder="请输入测试设备生产商"
                :rules="[{ required: true, message: '请输入测试设备生产商' }]"
              />
              <van-field
                :colon="true"
                v-model="canList[index].testModel"
                label="测试设备型号"
                placeholder="请输入测试设备型号"
                :rules="[{ required: true, message: '请输入测试设备型号' }]"
              />
              <van-field
                :colon="true"
                v-model="canList[index].methodBasis"
                label="方法依据"
                placeholder="请输入方法依据"
                :rules="[{ required: true, message: '请输入方法依据' }]"
              />
            </van-cell-group>
          </van-tab>
        </van-tabs>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="tester"
          label="测试人"
          placeholder="请输入测试人"
          :colon="true"
          :rules="[{ required: true, message: '请输入测试人' }]"
        />
        <van-field
          readonly
          :colon="true"
          clickable
          name="datetimePicker"
          :value="testTime"
          label="时间"
          placeholder="请选择时间"
          @click="dateTimeObj.pickerShow"
          :rules="[{ required: true, message: '选择时间' }]"
        />
        <van-field
          v-model="examine"
          label="审核"
          placeholder="请输入审核"
          :colon="true"
          :rules="[{ required: true, message: '请输入审核' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="datePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="datePickerObj.value"
        @confirm="datePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="timeObj.pickerValue"
        @confirm="timeObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimeObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimeObj.value"
        @confirm="dateTimeObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="onSelect"
      title="请选择要添加的记录表"
      :close-on-click-action="true"
    />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      show: false,
      actions: [
        { name: "CEMS主要仪器型号记录" },
        { name: "颗粒物校验记录" },
        { name: "SO2校验记录" },
        { name: "NOx校验记录" },
        { name: "O2校验记录" },
        { name: "流速校验记录" },
        { name: "烟温校验记录" },
        { name: "湿度校验记录" },
        { name: "标准气体记录" },
        { name: "参比方法测试设备记录" },
      ],
      active: "",
      showPicker: false,
      dateTimeObj: {
        isShow: false,
        value: new Date(),
        pickerShow: () => {
          this.dateTimeObj.isShow = false;
          if (!this.testTime) {
            this.dateTimeObj.value = new Date();
          } else {
            this.dateTimeObj.value = new Date(
              this.$moment(this.dateTime).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.testTime = this.$moment(this.dateTimeObj.value).format(
            "YYYY-MM-DD HH:mm"
          );
          this.dateTimeObj.isShow = false;
        },
      },
      dateTimePickerObj: {
        value: new Date(),
        type: "",
        index: 0,
        isShow: false,
        pickerShow: (index, type) => {
          this.dateTimePickerObj.isShow = true;
          this.dateTimePickerObj.index = index;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "matter":
              if (!this.keList[this.dateTimePickerObj.index].checkTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.keList[this.dateTimePickerObj.index].checkTime
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkSo":
              if (!this.soList[this.dateTimePickerObj.index].checkTimeSo) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.soList[this.dateTimePickerObj.index].checkTimeSo
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkNo":
              if (!this.noList[this.dateTimePickerObj.index].checkTimeNo) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.noList[this.dateTimePickerObj.index].checkTimeNo
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkO":
              if (!this.oList[this.dateTimePickerObj.index].checkTimeO) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.oList[this.dateTimePickerObj.index].checkTimeO
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkLiu":
              if (!this.liuList[this.dateTimePickerObj.index].checkTimeLiu) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.liuList[this.dateTimePickerObj.index].checkTimeLiu
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkYan":
              if (!this.yanList[this.dateTimePickerObj.index].checkTimeYan) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.yanList[this.dateTimePickerObj.index].checkTimeYan
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkShi":
              if (!this.shiList[this.dateTimePickerObj.index].checkTimeShi) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(
                    this.shiList[this.dateTimePickerObj.index].checkTimeShi
                  ).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "checkTest":
              if (!this.testTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.testTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;

            // case "calibration":
            //   if (!this.list[this.dateTimePickerObj.index].calibrationTime) {
            //     this.dateTimePickerObj.value = new Date();
            //   } else {
            //     this.dateTimePickerObj.value = new Date(
            //       this.$moment(
            //         this.list[this.dateTimePickerObj.index].calibrationTime
            //       ).format("MM DD,YYYY HH:mm:ss")
            //     );
            //   }
            //   break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "matter":
              this.keList[this.dateTimePickerObj.index].checkTime =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkSo":
              this.soList[this.dateTimePickerObj.index].checkTimeSo =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkNo":
              this.noList[this.dateTimePickerObj.index].checkTimeNo =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkO":
              this.oList[this.dateTimePickerObj.index].checkTimeO =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkLiu":
              this.liuList[this.dateTimePickerObj.index].checkTimeLiu =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkYan":
              this.yanList[this.dateTimePickerObj.index].checkTimeYan =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkShi":
              this.shiList[this.dateTimePickerObj.index].checkTimeShi =
                this.$moment(this.dateTimePickerObj.value).format(
                  "YYYY-MM-DD HH:mm"
                );
              break;
            case "checkTest":
              this.testTime = this.$moment(this.dateTimePickerObj.value).format(
                "YYYY-MM-DD HH:mm"
              );
              break;
          }
        },
      },
      datePickerObj: {
        value: new Date(),
        isShow: false,
        pickerShow: () => {
          this.datePickerObj.isShow = true;
          if (!this.time) {
            this.datePickerObj.value = new Date();
          } else {
            this.datePickerObj.value = new Date(
              this.$moment(this.time).format("MM DD,YYYY HH:mm:ss")
            );
          }
        },
        pickerConfirm: () => {
          this.datePickerObj.isShow = false;
          this.time = this.$moment(this.datePickerObj.value).format(
            "YYYY-MM-DD"
          );
        },
      },
      timeObj: {
        pickerValue: new Date(),
        pickerIndex: "",
        pickerShow: (index) => {
          this.showPicker = true;
          this.timeObj.pickerIndex = index;
          if (!this.dateTime) {
            this.timeObj.pickerValue = new Date();
          } else {
            this.timeObj.pickerValue = new Date(
              this.$moment(this.dateTime).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTime = this.$moment(this.timeObj.pickerValue).format(
            "YYYY-MM-DD"
          );
          this.showPicker = false;
        },
      },

      enterprise: "",
      supplier: "",
      address: "",
      company: "",
      time: "",
      dateTime: "",

      allList: {
        error: "1",
        errorValue: "",
        criterion: "根据HJ/T75-2017",
        result: "",
        evaluation: "",
        evaluationCems: "",

        errorSo: "1",
        errorValueSo: "",
        criterionSo: "根据HJ/T75-2017",
        resultSo: "",
        evaluationSo: "",
        evaluationSoCems: "",

        errorNo: "1",
        errorValueNo: "",
        criterionNo: "根据HJ/T75-2017",
        resultNo: "",
        evaluationNo: "",
        evaluationNoCems: "",

        errorO: "1",
        errorValueO: "",
        criterionO: "根据HJ/T75-2017",
        resultO: "",
        evaluationO: "",
        evaluationOCems: "",

        errorLiu: "1",
        errorValueLiu: "",
        criterionLiu: "根据HJ/T75-2017",
        resultLiu: "",
        evaluationLiu: "",
        evaluationLiuCems: "",

        errorValueYan: "",
        criterionYan: "根据HJ/T75-2017",
        resultYan: "",
        evaluationYan: "",
        evaluationYanCems: "",

        errorShi: "1",
        errorValueShi: "",
        criterionShi: "根据HJ/T75-2017",
        resultShi: "",
        evaluationShi: "",
        evaluationShiCems: "",

        explainBefore: "",
        explainAfter: "",
        qualified: "1",
      },
      tester: "",
      testTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      examine: "",
      keList: [
        {
          checkTime: "",
          reference: "",
          measured: "",
        },
      ],
      list: [
        {
          devName: "",
          model: "",
          manufacturer: "",
          items: "",
          principle: "",
        },
      ],

      soList: [
        {
          checkTimeSo: "",
          referenceSo: "",
          measuredSo: "",
        },
      ],
      noList: [
        {
          checkTimeNo: "",
          referenceNo: "",
          measuredNo: "",
        },
      ],

      oList: [
        {
          checkTimeO: "",
          referenceO: "",
          measuredO: "",
        },
      ],

      liuList: [
        {
          checkTimeLiu: "",
          referenceLiu: "",
          measuredLiu: "",
        },
      ],

      yanList: [
        {
          checkTimeYan: "",
          referenceYan: "",
          measuredYan: "",
        },
      ],
      // errorYan: "1",
      shiList: [
        {
          checkTimeShi: "",
          referenceShi: "",
          measuredShi: "",
        },
      ],

      gasList: [
        {
          gasName: "",
          concentration: "",
          manufacturer: "",
        },
      ],
      canList: [
        {
          testProject: "",
          testEquipment: "",
          testModel: "",
          methodBasis: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    showList() {
      this.show = true;
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      // this.show = false;
      switch (item.name) {
        case "CEMS主要仪器型号记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加CEMS主要仪器型号新记录？",
          }).then(() => {
            this.list.push({
              devName: "",
              model: "",
              manufacturer: "",
              items: "",
              principle: "",
            });
            //  this.$nextTick(function() {
            //     document.getElementById("item" + index).scrollIntoView();
            //   });
          });
          break;
        case "颗粒物校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加颗粒物校验新记录？",
          }).then(() => {
            this.keList.push({
              checkTime: "",
              reference: "",
              measured: "",
            });
          });
          break;
        case "SO2校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加SO2校验新记录？",
          }).then(() => {
            this.soList.push({
              checkTimeSo: "",
              referenceSo: "",
              measuredSo: "",
            });
          });
          break;
        case "NOx校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加NOx校验新记录？",
          }).then(() => {
            this.noList.push({
              checkTimeNo: "",
              referenceNo: "",
              measuredNo: "",
            });
          });
          break;
        case "O2校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加O2校验新记录？",
          }).then(() => {
            this.oList.push({
              checkTimeO: "",
              referenceO: "",
              measuredO: "",
            });
          });
          break;
        case "流速校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加流速校验新记录？",
          }).then(() => {
            this.liuList.push({
              checkTimeLiu: "",
              referenceLiu: "",
              measuredLiu: "",
            });
          });
          break;
        case "烟温校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加烟温校验新记录？",
          }).then(() => {
            this.yanList.push({
              checkTimeYan: "",
              referenceYan: "",
              measuredYan: "",
            });
          });
          break;
        case "湿度校验记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加湿度校验新记录？",
          }).then(() => {
            this.shiList.push({
              checkTimeShi: "",
              referenceShi: "",
              measuredShi: "",
            });
          });
          break;
        case "标准气体记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加标准气体新记录？",
          }).then(() => {
            this.gasList.push({
              gasName: "",
              concentration: "",
              manufacturer: "",
            });
          });
          break;
        case "参比方法测试设备记录":
          Dialog.confirm({
            title: "添加新记录",
            message: "是否添加参比方法测试设备新记录？",
          }).then(() => {
            this.canList.push({
              testProject: "",
              testEquipment: "",
              testModel: "",
              methodBasis: "",
            });
          });
          break;
      }
      // Toast(item.name);
    },
    // 删除记录
    deleteList(type, index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + (index + 1) + "）？",
      })
        .then(() => {
          switch (type) {
            case "main":
              this.list.splice(index, 1);
              if (this.list.length <= 0) {
                this.list.push({
                  devName: "",
                  model: "",
                  manufacturer: "",
                  items: "",
                  principle: "",
                });
              }
              break;
            case "matter":
              this.keList.splice(index, 1);
              if (this.keList.length <= 0) {
                this.keList.push({
                  checkTime: "",
                  reference: "",
                  measured: "",
                });
              }
              break;
            case "checkSo":
              this.soList.splice(index, 1);
              if (this.soList.length <= 0) {
                this.soList.push({
                  checkTimeSo: "",
                  referenceSo: "",
                  measuredSo: "",
                });
              }
              break;
            case "checkNo":
              this.noList.splice(index, 1);
              if (this.noList.length <= 0) {
                this.noList.push({
                  checkTimeNo: "",
                  referenceNo: "",
                  measuredNo: "",
                });
              }
              break;
            case "checkO":
              this.oList.splice(index, 1);
              if (this.oList.length <= 0) {
                this.oList.push({
                  checkTimeO: "",
                  referenceO: "",
                  measuredO: "",
                });
              }
              break;
            case "checkLiu":
              this.liuList.splice(index, 1);
              if (this.liuList.length <= 0) {
                this.liuList.push({
                  checkTimeLiu: "",
                  referenceLiu: "",
                  measuredLiu: "",
                });
              }
              break;

            case "checkShi":
              this.shiList.splice(index, 1);
              if (this.shiList.length <= 0) {
                this.shiList.push({
                  checkTimeShi: "",
                  referenceShi: "",
                  measuredShi: "",
                });
              }
              break;
            case "checkGas":
              this.gasList.splice(index, 1);
              if (this.gasList.length <= 0) {
                this.gasList.push({
                  gasName: "",
                  concentration: "",
                  manufacturer: "",
                });
              }
              break;
            case "checkCan":
              this.canList.splice(index, 1);
              if (this.canList.length <= 0) {
                this.canList.push({
                  testProject: "",
                  testEquipment: "",
                  testModel: "",
                  methodBasis: "",
                });
              }
              break;
          }
        })
        .catch(() => {});
    },
    // 添加新记录
    // addList(type, index) {
    //   Dialog.confirm({
    //     title: "添加新记录",
    //     message: "是否添加新记录？"
    //   })
    //     .then(() => {
    //       switch (type) {
    //         case "main":
    //           this.list.push({
    //             devName: "",
    //             model: "",
    //             manufacturer: "",
    //             items: "",
    //             principle: ""
    //           });
    //           this.$nextTick(function() {
    //             document.getElementById("item" + index).scrollIntoView();
    //           });
    //           break;
    //         case "matter":
    //           this.keList.push({
    //             checkTime: "",
    //             reference: "",
    //             measured: ""
    //           });
    //           break;
    //         case "checkSo":
    //           this.soList.push({
    //             checkTimeSo: "",
    //             referenceSo: "",
    //             measuredSo: ""
    //           });
    //           break;
    //         case "checkNo":
    //           this.noList.push({
    //             checkTimeNo: "",
    //             referenceNo: "",
    //             measuredNo: ""
    //           });
    //           break;
    //         case "checkO":
    //           this.oList.push({
    //             checkTimeO: "",
    //             referenceO: "",
    //             measuredO: ""
    //           });
    //           break;
    //         case "checkLiu":
    //           this.liuList.push({
    //             checkTimeLiu: "",
    //             referenceLiu: "",
    //             measuredLiu: ""
    //           });
    //           break;
    //         case "checkYan":
    //           this.yanList.push({
    //             checkTimeYan: "",
    //             referenceYan: "",
    //             measuredYan: ""
    //           });
    //           break;
    //         case "checkShi":
    //           this.shiList.push({
    //             checkTimeShi: "",
    //             referenceShi: "",
    //             measuredShi: ""
    //           });
    //           break;
    //         case "checkGas":
    //           this.gasList.push({
    //             gasName: "",
    //             concentration: "",
    //             manufacturer: ""
    //           });
    //           break;
    //         case "checkCan":
    //           this.canList.push({
    //             testProject: "",
    //             testEquipment: "",
    //             testModel: "",
    //             methodBasis: ""
    //           });
    //           break;
    //       }
    //     })
    //     .catch(() => {});
    // },
    onSubmit() {
      Toast.loading("提交中...");
      const obj = {
        noList: this.noList,
        list: this.list,
        keList: this.keList,
        soList: this.soList,
        oList: this.oList,
        liuList: this.liuList,
        yanList: this.yanList,
        shiList: this.shiList,
        gasList: this.gasList,
        canList: this.canList,

        enterprise: this.$route.query.enterpriseName,
        supplier: this.supplier,
        address: this.address,
        company: this.company,
        time: this.time,
        dateTime: this.dateTime,
        allList: this.allList,

        // error: this.error,
        // errorValue: this.errorValue,
        // criterion: this.criterion,
        // result: this.result,
        // evaluation: this.evaluation,
        // evaluationCems: this.evaluationCems,

        // errorSo: this.errorSo,
        // errorValueSo: this.errorValueSo,
        // criterionSo: this.criterionSo,
        // resultSo: this.resultSo,
        // evaluationSo: this.evaluationSo,
        // evaluationSoCems: this.evaluationSoCems,

        // errorNo: this.errorNo,
        // errorValueNo: this.errorValueNo,
        // criterionNo: this.criterionNo,
        // resultNo: this.resultNo,
        // evaluationNo: this.evaluationNo,
        // evaluationNoCems: this.evaluationNoCems,

        // errorO: this.errorO,
        // errorValueO: this.errorValueO,
        // criterionO: this.criterionO,
        // resultO: this.resultO,
        // evaluationO: this.evaluationO,
        // evaluationOCems: this.evaluationOCems,

        // errorLiu: this.errorLiu,
        // errorValueLiu: this.errorValueLiu,
        // criterionLiu: this.criterionLiu,
        // resultLiu: this.resultLiu,
        // evaluationLiu: this.evaluationLiu,
        // evaluationLiuCems: this.evaluationLiuCems,

        // errorValueYan: this.errorValueYan,
        // criterionYan: this.criterionYan,
        // resultYan: this.resultYan,
        // evaluationYan: this.evaluationYan,
        // evaluationYanCems: this.evaluationYanCems,

        // errorShi: this.errorShi,
        // errorValueShi: this.errorValueShi,
        // criterionShi: this.criterionShi,
        // resultShi: this.resultShi,
        // evaluationShi: this.evaluationShi,
        // evaluationShiCems: this.evaluationShiCems,

        // explainBefore: this.explainBefore,
        // explainAfter: this.explainAfter,
        // qualified: this.qualified,

        tester: this.tester,
        testTime: this.testTime,
        examine: this.examine,
      };

      for (let keyallList = 0; keyallList < this.allList.length; keyallList++) {
        if (this.allList[keyallList] === "") {
          Toast.fail("请填写完整的信息");
          return;
        }
      }

      for (let keycanList = 0; keycanList < this.canList.length; keycanList++) {
        for (
          let icanList = 0;
          icanList < this.canList[keycanList].length;
          icanList++
        ) {
          if (this.canList[keycanList][icanList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keygasList = 0; keygasList < this.gasList.length; keygasList++) {
        for (
          let igasList = 0;
          igasList < this.gasList[keygasList].length;
          igasList++
        ) {
          if (this.gasList[keygasList][igasList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keyshiList = 0; keyshiList < this.shiList.length; keyshiList++) {
        for (
          let ishiList = 0;
          ishiList < this.shiList[keyshiList].length;
          ishiList++
        ) {
          if (this.shiList[keyshiList][ishiList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keyyanList = 0; keyyanList < this.yanList.length; keyyanList++) {
        for (
          let iyanList = 0;
          iyanList < this.yanList[keyyanList].length;
          iyanList++
        ) {
          if (this.yanList[keyyanList][iyanList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }
      for (let keyliuList = 0; keyliuList < this.liuList.length; keyliuList++) {
        for (
          let iliuList = 0;
          iliuList < this.liuList[keyliuList].length;
          iliuList++
        ) {
          if (this.liuList[keyliuList][iliuList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keyoList = 0; keyoList < this.oList.length; keyoList++) {
        for (let ioList = 0; ioList < this.oList[keyoList].length; ioList++) {
          if (this.oList[keyoList][ioList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keykeList = 0; keykeList < this.keList.length; keykeList++) {
        for (
          let ikeList = 0;
          ikeList < this.keList[keykeList].length;
          ikeList++
        ) {
          if (this.keList[keykeList][ikeList] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keylist = 0; keylist < this.list.length; keylist++) {
        for (let ilist = 0; ilist < this.list[keylist].length; ilist++) {
          if (this.list[keylist][ilist] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let key = 0; key < this.soList.length; key++) {
        for (let i = 0; i < this.soList[key].length; i++) {
          if (this.soList[key][i] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      for (let keyNo = 0; keyNo < this.noList.length; keyNo++) {
        for (let iNo = 0; iNo < this.noList[keyNo].length; iNo++) {
          if (this.noList[keyNo][iNo] === "") {
            Toast.fail("请填写完整的信息");
            return;
          }
        }
      }

      const content = JSON.stringify(obj);
      const data = {
        // content: content,
        // enterpriseId: "1262280304815538178",
        // pointId: "1273432174032388211",
        // userId: "1",
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 9,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.van-tabs__content {
  .van-cell-group__title {
    background-color: #e9eef2;
  }
}
.event {
  .van-radio--horizontal {
    margin-right: 0.71rem;
  }
  .van-action-sheet {
    max-height: 52%;
  }
}
</style>
