<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="试剂更换记录表" fixed left-arrow @click-left="goBack">
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group v-for="(item, index) in list" :key="index">
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>记录({{ index + 1 }})</span>
            <span
              ><van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList(index)"
            /></span>
          </div>
        </template>
        <van-field
          v-model="list[index].reagent"
          label="试剂名称"
          :colon="true"
          placeholder="请输入试剂名称"
          :rules="[{ required: true, message: '请输入试剂名称' }]"
        />
        <van-field
          v-model="list[index].devName"
          label="设备名称"
          :colon="true"
          placeholder="请输入设备名称"
          :rules="[{ required: true, message: '请输入设备名称' }]"
        />
        <van-field
          v-model="list[index].quantity"
          label="更换数量（单位:L）"
          :colon="true"
          placeholder="请输入更换数量"
          :rules="[{ required: true, message: '请输入更换数量' }]"
        />
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="list[index].dateTime"
          label="更换日期"
          placeholder="更换日期"
          @click="timeObj.pickerShow(index)"
          :rules="[{ required: true, message: '请输入更换日期' }]"
        />
        <van-field
          :colon="true"
          v-model="list[index].personnel"
          label="更换人员"
          placeholder="请输入更换人员"
          :rules="[{ required: true, message: '请输入更换人员' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px" id="item">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="timeObj.pickerValue"
        @confirm="timeObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      showPicker: false,
      timeObj: {
        pickerValue: new Date(),
        pickerIndex: "",
        pickerShow: (index) => {
          this.showPicker = true;
          this.timeObj.pickerIndex = index;
          if (!this.list[this.timeObj.pickerIndex].dateTime) {
            this.timeObj.pickerValue = new Date();
          } else {
            this.timeObj.pickerValue = new Date(
              this.$moment(this.list[this.timeObj.pickerIndex].dateTime).format(
                "YYYY/MM/DD"
              )
            );
          }
        },
        pickerConfirm: () => {
          this.list[this.timeObj.pickerIndex].dateTime = this.$moment(
            this.timeObj.pickerValue
          ).format("YYYY-MM-DD");
          this.showPicker = false;
        },
      },
      list: [
        {
          reagent: "",
          devName: "",
          quantity: "",
          dateTime: "",
          personnel: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 删除记录
    deleteList(index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + (index + 1) + "）？",
      })
        .then(() => {
          this.list.splice(index, 1);
          if (this.list.length <= 0) {
            this.list.push({
              reagent: "",
              devName: "",
              quantity: "",
              dateTime: "",
              personnel: "",
            });
          }
        })
        .catch(() => {});
    },
    // 添加新记录
    addList() {
      Dialog.confirm({
        title: "添加新记录",
        message: "是否添加新记录？",
      })
        .then(() => {
          this.list.push({
            reagent: "",
            devName: "",
            quantity: "",
            dateTime: "",
            personnel: "",
          });
          this.$nextTick(function () {
            document.getElementById("item").scrollIntoView();
          });
        })
        .catch(() => {});
    },
    onSubmit() {
      const obj = {
        pointName: this.pointName,
        list: this.list,
      };
      const content = JSON.stringify(obj);
      const data = {
        // content: content,
        // enterpriseId: "1262280304815538178",
        // sn: this.$router.query.sn,
        // userId: localStorage.getItem("userId"),
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 4,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
