<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="易耗品更换记录" fixed left-arrow @click-left="goBack">
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          readonly
          v-model="this.$route.query.enterpriseName"
          label="企业名称"
          placeholder="请输入企业名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="dateTime"
          label="日期"
          placeholder="点击选择日期"
          :colon="true"
          @click="dataPickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择日期' }]"
        />
        <van-field
          v-model="place"
          label="安装地点"
          placeholder="请输入安装地点"
          :colon="true"
          :rules="[{ required: true, message: '请输入安装地点' }]"
        />
        <van-field
          v-model="management"
          label="维护管理单位"
          placeholder="请输入维护管理单位"
          :colon="true"
          :rules="[{ required: true, message: '请输入维护管理单位' }]"
        />
      </van-cell-group>
      <van-cell-group v-for="(item, index) in list" :key="index">
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>记录({{ index + 1 }})</span>
            <span
              ><van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList(index)"
            /></span>
          </div>
        </template>
        <van-field
          readonly
          clickable
          :colon="true"
          name="datetimePicker"
          :value="list[index].replaceTime"
          label="更换日期"
          placeholder="请选择更换日期"
          @click="timeObj.pickerShow(index)"
          :rules="[{ required: true, message: '请选择更换日期' }]"
        />
        <van-field
          v-model="list[index].consumables"
          label="易耗品名称"
          placeholder="请输入易耗品名称"
          :colon="true"
          :rules="[{ required: true, message: '请输入易耗品名称' }]"
        />
        <van-field
          v-model="list[index].specification"
          label="规格型号"
          placeholder="请输入规格型号"
          :colon="true"
          :rules="[{ required: true, message: '请输入规格型号' }]"
        />
        <van-field
          v-model="list[index].company"
          label="单位"
          placeholder="请输入单位"
          :colon="true"
          :rules="[{ required: true, message: '请输入单位' }]"
        />
        <van-field
          v-model="list[index].number"
          label="数量"
          placeholder="请输入数量"
          :colon="true"
          :rules="[{ required: true, message: '请输入数量' }]"
        />
        <van-field
          v-model="list[index].remark"
          label="更换原因说明(备注)"
          placeholder="请输入更换原因说明(备注)"
          type="textarea"
          :colon="true"
          :rules="[{ required: true, message: '请输入更换原因说明(备注)' }]"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="maintainer"
          label="维护保养人"
          placeholder="请输入维护保养人"
          :colon="true"
          :rules="[{ required: true, message: '请输入维护保养人' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="firstTime"
          label="时间"
          placeholder="点击选择时间"
          :colon="true"
          @click="dateTimePickerObj.pickerShow('first')"
          :rules="[{ required: true, message: '请输入时间' }]"
        />
        <van-field
          v-model="approved"
          label="审批人"
          placeholder="请输入审批人"
          :colon="true"
          :rules="[{ required: true, message: '请输入审批人' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="secondTime"
          label="时间"
          placeholder="点击选择时间"
          :colon="true"
          @click="dateTimePickerObj.pickerShow('second')"
          :rules="[{ required: true, message: '请输入时间' }]"
        />
      </van-cell-group>
      <van-row style="margin: 16px" id="item">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="dataPickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="dataPickerObj.value"
        @confirm="dataPickerObj.pickerConfirm"
        @cancel="dataPickerObj.isShow = false"
      />
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="timeObj.pickerValue"
        @confirm="timeObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="dateTimePickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="dateTimePickerObj.value"
        @confirm="dateTimePickerObj.pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dateTimePickerObj: {
        value: new Date(),
        isShow: false,
        type: "",
        pickerShow: (type) => {
          this.dateTimePickerObj.isShow = false;
          this.dateTimePickerObj.type = type;
          switch (type) {
            case "first":
              if (!this.firstTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.firstTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
            case "second":
              if (!this.secondTime) {
                this.dateTimePickerObj.value = new Date();
              } else {
                this.dateTimePickerObj.value = new Date(
                  this.$moment(this.secondTime).format("MM DD,YYYY HH:mm:ss")
                );
              }
              break;
          }
        },
        pickerConfirm: () => {
          this.dateTimePickerObj.isShow = false;
          switch (this.dateTimePickerObj.type) {
            case "first":
              this.firstTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
            case "second":
              this.secondTime = this.$moment(
                this.dateTimePickerObj.value
              ).format("YYYY-MM-DD HH:mm");
              break;
          }
        },
      },
      showPicker: false,
      dataPickerObj: {
        isShow: false,
        value: new Date(),
        pickerShow: () => {
          this.dataPickerObj.isShow = false;
          if (!this.dateTime) {
            this.dataPickerObj.value = new Date();
          } else {
            this.dataPickerObj.value = new Date(
              this.$moment(this.dateTime).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTime = this.$moment(this.dataPickerObj.value).format(
            "YYYY-MM-DD"
          );
          this.dataPickerObj.isShow = false;
        },
      },
      timeObj: {
        pickerValue: new Date(),
        pickerIndex: "",
        pickerShow: (index) => {
          this.showPicker = true;
          this.timeObj.pickerIndex = index;
          if (!this.list[this.timeObj.pickerIndex].replaceTime) {
            this.timeObj.pickerValue = new Date();
          } else {
            this.timeObj.pickerValue = new Date(
              this.$moment(
                this.list[this.timeObj.pickerIndex].replaceTime
              ).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.list[this.timeObj.pickerIndex].replaceTime = this.$moment(
            this.timeObj.pickerValue
          ).format("YYYY-MM-DD");
          this.showPicker = false;
        },
      },
      dateTime: this.$moment().format("YYYY-MM-DD"),
      name: "",
      place: "",
      management: "",
      list: [
        {
          replaceTime: "",
          consumables: "",
          specification: "",
          company: "",
          number: "",
          remark: "",
        },
      ],
      maintainer: "",
      firstTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      approved: "",
      secondTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 删除记录
    deleteList(index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + index + "）？",
      })
        .then(() => {
          this.list.splice(index, 1);
          if (this.list.length <= 0) {
            this.list.push({
              replaceTime: "",
              consumables: "",
              specification: "",
              company: "",
              number: "",
              remark: "",
            });
          }
        })
        .catch(() => {});
    },
    // 添加新记录
    addList() {
      Dialog.confirm({
        title: "添加新记录",
        message: "是否添加新记录？",
      })
        .then(() => {
          this.list.push({
            replaceTime: "",
            consumables: "",
            specification: "",
            company: "",
            number: "",
            remark: "",
          });
          this.$nextTick(function () {
            document.getElementById("item").scrollIntoView();
          });
        })
        .catch(() => {});
    },
    // 确定
    onSubmit() {
      Toast.loading("提交中...");
      const obj = {
        name: this.$route.query.enterpriseName,
        dateTime: this.dateTime,
        place: this.place,
        management: this.management,
        list: this.list,
        maintainer: this.management,
        firstTime: this.firstTime,
        approved: this.approved,
        secondTime: this.secondTime,
      };
      const content = JSON.stringify(obj);
      const data = {
        // content: content,
        // enterpriseId: "1262280304815538178",
        // pointId: "1273432174032388211",
        // userId: "1",
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 12,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped></style>
