<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="表格" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-cell-group title="表格种类">
      <van-cell
        :title="item.title"
        is-link
        v-for="(item, index) in tableTypeList"
        :key="index"
        @click="toTablePath(item)"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      sn: "",
      enterpriseName: "",
      pointName: "",
      tableTypeList: [
        {
          title: "（水质）比对试验结果记录表",
          name: "Tabledbsyjgjlb",
        },
        {
          title: "（水质）废液收集记录表",
          name: "Tablefysjjlb",
        },
        {
          title: "（水质）故障维修记录表",
          name: "Tablegzwxjlb",
        },
        {
          title: "（水质）试剂更换记录表",
          name: "Tablesjghjlb",
        },
        {
          title: "（水质）标样核查及校准记录表",
          name: "Tablebyhcjjzjlb",
        },
        {
          title: "（水质）运营维护日常巡检表",
          name: "Tableyywhrcxjb",
        },
        {
          title: "（烟气）零漂、量漂校准记录表",
          name: "Tablelplpxzjlb",
        },
        {
          title: "（烟气）CEMS维修记录表",
          name: "Tableywxjlb",
        },
        {
          title: "（烟气）CEMS校验测试记录表",
          name: "Tablejycsjlb",
        },
        {
          title: "（烟气）标准气体更换记录表",
          name: "Tableybzqtghjlb",
        },
        {
          title: "（烟气）日常巡检维护记录表",
          name: "Tablercxjwhjlb",
        },
        {
          title: "（烟气）易耗品更换记录表",
          name: "Tableyhpghjlb",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.setSn();
  },
  methods: {
    setSn() {
      if (this.$route.query.sn) {
        this.sn = this.$route.query.sn;
        this.enterpriseName = this.$route.query.enterpriseName;
        this.pointName = this.$route.query.pointName;
      }
    },
    toTablePath(item) {
      this.$router.push({
        name: item.name,
        query: {
          sn: this.sn,
          enterpriseName: this.enterpriseName,
          pointName: this.pointName,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
