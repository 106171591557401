<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar
      title="比对试验结果记录表"
      fixed
      left-arrow
      @click-left="goBack"
    >
      <template slot="right">
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <van-field
          v-model="name"
          label="运行方代表"
          placeholder="请输入运行方代表"
          :colon="true"
          :rules="[{ required: true, message: '请输入站点名称' }]"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="dateTime"
          label="日期"
          placeholder="点击选择日期"
          :colon="true"
          @click="dataPickerObj.pickerShow"
          :rules="[{ required: true, message: '请选择日期' }]"
        />
      </van-cell-group>
      <van-cell-group v-for="(item, index) in list" :key="index">
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>记录({{ index + 1 }})</span>
            <span
              ><van-icon
                name="delete"
                color="#ee0a24"
                size="18"
                @click="deleteList(index)"
            /></span>
          </div>
        </template>
        <van-field
          v-model="list[index].projectName"
          label="测试项目"
          placeholder="请输入测试项目"
          :colon="true"
          :rules="[{ required: true, message: '请输入测试项目' }]"
        />
        <van-field
          v-model="list[index].result"
          label="在线监测仪器测定结果"
          placeholder="请输入在线监测仪器测定结果"
          :colon="true"
          :rules="[{ required: true, message: '请输入在线监测仪器测定结果' }]"
        />
        <van-field
          v-model="list[index].result1"
          label="比对方法测定结果（1）"
          placeholder="请输入比对方法测定结果（1）"
          :colon="true"
          :rules="[{ required: true, message: '请输入比对方法测定结果（1）' }]"
        />
        <van-field
          v-model="list[index].result2"
          label="比对方法测定结果（2）"
          placeholder="请输入比对方法测定结果（2）"
          :colon="true"
          :rules="[{ required: true, message: '请输入比对方法测定结果（2）' }]"
          @focus="onShowKeyboard(index)"
          :class="'scroll' + index"
        />
        <van-field
          v-model="list[index].average"
          label="比对监测结果平均值"
          placeholder="请输入比对监测结果平均值"
          :colon="true"
          :rules="[{ required: true, message: '请输入比对监测结果平均值' }]"
          class="'scroll'+index"
          @focus="onShowKeyboard(index)"
        />
        <van-field
          v-model="list[index].error"
          label="测定误差（％）"
          placeholder="请输入测定误差（％）"
          :colon="true"
          :rules="[{ required: true, message: '请输入测定误差（％）' }]"
          class="'scroll'+index"
          @focus="onShowKeyboard(index)"
        />
        <van-cell title="是否合格:">
          <van-radio-group
            v-model="list[index].isQualified"
            direction="horizontal"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
      <van-row style="margin: 16px" id="item">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup v-model="dataPickerObj.isShow" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="dataPickerObj.value"
        @confirm="dataPickerObj.pickerConfirm"
        @cancel="dataPickerObj.isShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      dataPickerObj: {
        isShow: false,
        value: new Date(),
        pickerShow: () => {
          this.dataPickerObj.isShow = false;
          if (!this.dateTime) {
            this.dataPickerObj.value = new Date();
          } else {
            this.dataPickerObj.value = new Date(
              this.$moment(this.dateTime).format("YYYY/MM/DD")
            );
          }
        },
        pickerConfirm: () => {
          this.dateTime = this.$moment(this.dataPickerObj.value).format(
            "YYYY-MM-DD"
          );
          this.dataPickerObj.isShow = false;
        },
      },
      dateTime: this.$moment().format("YYYY-MM-DD"),
      name: "",
      list: [
        {
          projectName: "",
          result: "",
          result1: "",
          result2: "",
          average: "",
          isQualified: "1",
        },
      ],
      index: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 删除记录
    deleteList(index) {
      Dialog.confirm({
        title: "删除记录",
        message: "是否删除记录（" + (index + 1) + "）？",
      })
        .then(() => {
          this.list.splice(index, 1);
          if (this.list.length <= 0) {
            this.list.push({
              projectName: "",
              result: "",
              result1: "",
              result2: "",
              average: "",
              isQualified: "1",
            });
          }
        })
        .catch(() => {});
    },
    // 添加新记录
    addList() {
      Dialog.confirm({
        title: "添加新记录",
        message: "是否添加新记录？",
      })
        .then(() => {
          this.list.push({
            projectName: "",
            result: "",
            result1: "",
            result2: "",
            average: "",
            isQualified: "1",
          });
          this.$nextTick(function () {
            document.getElementById("item").scrollIntoView();
          });
        })
        .catch(() => {});
    },
    // 确定
    onSubmit() {
      Toast.loading("提交中...");
      const obj = {
        name: this.name,
        dateTime: this.dateTime,
        list: this.list,
      };
      const content = JSON.stringify(obj);
      const data = {
        content,
        sn: this.$route.query.sn,
        userId: localStorage.getItem("userId"),
        type: 1,
      };
      this.$api.table
        .postAddArcTabulation(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.clear();
            Toast.success("提交成功");
            this.goBack();
          }
        })
        .catch(() => {
          Toast.clear();
          Toast.fail("提交失败");
        })
        .finally(() => {});
    },
    onShowKeyboard(index) {
      const that = document.querySelector(".scroll" + index);
      setTimeout(() => {
        that.scrollIntoView();
      }, 100);
    },
  },
};
</script>

<style lang="less" scoped></style>
